import * as React from 'react';

import liff from '@line/liff';
import axios from 'axios';
import Toolbar from '@mui/material/Toolbar';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';

class LiquidFormProps {
  entry: any;
}

let liquidLoaded = false;

const LiquidForm: React.FC<LiquidFormProps> = ({ entry }) => {
  if (!liquidLoaded) {
    liquidLoaded = true;

    const context: any = liff.getContext();
    axios
      .get(
        `https://asia-northeast1-little-help-connect.cloudfunctions.net/getLiquidUrl?uid=${
          entry.properties.staff_code
        }&liffid=${context ? context.liffId : ''}`,
      )
      .then((response) => {
        console.log(response.data);

        liff.openWindow({
          url: response.data.application_url,
          external: true,
        });
      });
  }

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            {'車輛通勤許可申請'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div
        style={{
          padding: '1em',
        }}
      >
        {'アップロード画面を起動中...'}
      </div>
    </span>
  );
};

export default LiquidForm;
