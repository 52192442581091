import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

import liff from '@line/liff';
import { RootState } from '../../app/store';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';
const API_KEY = process.env.REACT_APP_CONTACT_API_KEY || '';
const OBJECT_TYPE = process.env.REACT_APP_ENTRY_OBJECT_TYPE || '';
const PIPELINE = process.env.REACT_APP_ENTRY_PIPELINE || '';
const PIPELINE_STAGE = process.env.REACT_APP_ENTRY_PIPELINE_STAGE || '';
const CS_PIPELINE = process.env.REACT_APP_ENTRY_CS_PIPELINE || '';
const CS_PIPELINE_STAGE = process.env.REACT_APP_ENTRY_CS_PIPELINE_STAGE || '';

export const entryContact = createAsyncThunk(
  'entryContact',
  async (params: any) => {
    try {
      let data: any = {
        objectType: `${OBJECT_TYPE}`,
        associationType: '入職手続き_to_contact',
        // associationType: '入社手続き_to_contact',
        properties: {
          // パイプライン
          hs_pipeline:
            params.values.branch === '新宿CS支店' ||
            params.values.branch === 'エンジニア事業部 北海道' ||
            params.values.branch === 'エンジニア事業部 仙台' ||
            params.values.branch === 'エンジニア事業部 新宿' ||
            params.values.branch === 'エンジニア事業部 東海' ||
            params.values.branch === 'エンジニア事業部 関西' ||
            params.values.branch === 'エンジニア事業部 広島' ||
            params.values.branch === 'エンジニア事業部 九州'
              ? CS_PIPELINE
              : PIPELINE,
          hs_pipeline_stage:
            params.values.branch === '新宿CS支店' ||
            params.values.branch === 'エンジニア事業部 北海道' ||
            params.values.branch === 'エンジニア事業部 仙台' ||
            params.values.branch === 'エンジニア事業部 新宿' ||
            params.values.branch === 'エンジニア事業部 東海' ||
            params.values.branch === 'エンジニア事業部 関西' ||
            params.values.branch === 'エンジニア事業部 広島' ||
            params.values.branch === 'エンジニア事業部 九州'
              ? CS_PIPELINE_STAGE
              : PIPELINE_STAGE,

          //誓約書に同意
          name: params.values.lastname + ' ' + params.values.firstname,
          written_oath: params.values.writtenOath,
          emergency_lastname: params.values.emergencyLastname,
          emergency_firstname: params.values.emergencyFirstname,
          emergency_relationship: params.values.emergencyRelationship,
          emergency_tel_number: params.values.emergencyTelNumber,

          //必要項目
          lastname: params.values.lastname,
          lastname_ruby: params.values.lastnameRuby,
          firstname: params.values.firstname,
          firstname_ruby: params.values.firstnameRuby,
          birthday: params.values.birthday,
          staff_code: params.values.staffCode,
          staff_code_digit: params.values.staffCodeDigit,
          gender: params.values.gender,
          start_date: params.values.startDate,
          branch: params.values.branch,
          post_code1: params.values.postCode1,
          prefecture1: params.values.prefecture1,
          municipalities1: params.values.municipalities1,
          town_area1: params.values.townArea1,
          other_address1: params.values.otherAddress1,
          registration_address: params.values.registrationAddress,
          post_code2: params.values.postCode2,
          prefecture2: params.values.prefecture2,
          municipalities2: params.values.municipalities2,
          town_area2: params.values.townArea2,
          other_address2: params.values.otherAddress2,

          //マル扶(申告者)
          lastname3: params.values.lastname3,
          firstname3: params.values.firstname3,
          relationship3: params.values.relationship3,
          handicapped: params.values.handicapped,
          handicapped_detail: params.values.handicappedDetail,
          handicapped_degree: params.values.handicappedDegree,
          handicappedother: params.values.handicappedOther,
          delivery_date: params.values.deliveryDate,
          working_student: params.values.workingStudent,
          school_label: params.values.schoolLabel,
          school_name: params.values.schoolName,
          admission_date: params.values.admissionDate,
          household: params.values.household,
          marriage: params.values.marriage,
          dependent_declaration: params.values.dependentDeclaration,
          situation_selection: params.values.situationSelection,

          //その他
          column_b_declaration: params.values.columnBDeclaration,
          cars_for_commuting: params.values.carsForCommuting,
          commute_start_day: params.values.commuteStartDay,
          car_number: params.values.carNumber,
          car_number_s1: params.values.carNumberS1,
          car_number_s2: params.values.carNumberS2,
          car_number_s3: params.values.carNumberS3,
          car_name: params.values.carName,
          car_color: params.values.carColor,
          application_distinction: params.values.applicationDistinction,
          cars_type: params.values.carsType,
          diligence_name: params.values.diligenceName,
          written_oath_cars_commute: params.values.writtenOathCarsCommute,

          //研修
          training: params.values.training,

          // システム情報
          line_id: params.userId,

          //DR
          dr_written_oath: params.values.drWrittenOath,
          dr_confirming_letter: params.values.drConfirmingLetter,
          dr_personal_information_240901: params.values.drPersonalInformation,

          dr_confirming_letter_date: params.values.drConfirmingLetter
            ? moment().format('YYYY-MM-DD')
            : '',
          dr_written_oath_date: params.values.drWrittenOath
            ? moment().format('YYYY-MM-DD')
            : '',
          dr_personal_information_date_240901: params.values
            .drPersonalInformation
            ? moment().format('YYYY-MM-DD')
            : '',
        },
      };

      //マル扶(配偶者)
      if (params.values.dependentDeclaration === 'はい') {
        data = {
          ...data,
          properties: {
            ...data.properties,
            partner_relationship: params.values.partnerRelationship,
            partner_lastname: params.values.partnerLastname,
            partner_lastname_ruby: params.values.partnerLastnameRuby,
            partner_firstname: params.values.partnerFirstname,
            partner_firstname_ruby: params.values.partnerFirstnameRuby,
            partner_birthday: params.values.partnerBirthday,
            partner_gender: params.values.partnerGender,
            partner_salary: params.values.partnerSalary,
            partner_pension: params.values.partnerPension,
            partner_other_income: params.values.partnerOtherIncome,
            partner_living: params.values.partnerLiving,
            partner_post_code: params.values.partnerPostCode,
            partner_prefecture: params.values.partnerPrefecture,
            partner_municipalities: params.values.partnerMunicipalities,
            partner_town_area: params.values.partnerTownArea,
            partner_other_address: params.values.partnerOtherAddress,
            partner_handicapped: params.values.partnerHandicapped,
            partner_handicapped_detail: params.values.partnerHandicappedDetail,
            partner_handicapped_degree: params.values.partnerHandicappedDegree,
            partnerhandicappedother: params.values.partnerHandicappedOther,
            partner_delivery_date: params.values.partnerDeliveryDate,
            partner_bereavement: params.values.partnerBereavement,
          },
        };
      } else {
        data = {
          ...data,
          properties: {
            ...data.properties,
            partner_relationship: '',
            partner_lastname: '',
            partner_lastname_ruby: '',
            partner_firstname: '',
            partner_firstname_ruby: '',
            partner_birthday: '',
            partner_gender: '',
            partner_salary: '',
            partner_pension: '',
            partner_other_income: '',
            partner_living: '',
            partner_post_code: '',
            partner_prefecture: '',
            partner_municipalities: '',
            partner_town_area: '',
            partner_other_address: '',
            partner_handicapped: '',
            partner_handicapped_detail: '',
            partner_handicapped_degree: '',
            partnerhandicappedother: '',
            partner_delivery_date: '',
            partner_bereavement: '',
          },
        };
      }

      //マル扶(扶養親族)
      if (params.values.relativesLastname1) {
        data = {
          ...data,
          properties: {
            ...data.properties,
            relatives_lastname1: params.values.relativesLastname1,
            relatives_lastname_ruby1: params.values.relativesLastnameRuby1,
            relatives_firstname1: params.values.relativesFirstname1,
            relatives_firstname_ruby1: params.values.relativesFirstnameRuby1,
            relatives_birthday1: params.values.relativesBirthday1,
            relatives_gender1: params.values.relativesGender1,
            relatives_relationship1: params.values.relativesRelationship1,
            relatives_salary1: params.values.relativesSalary1,
            relatives_pension1: params.values.relativesPension1,
            relatives_other_income1: params.values.relativesOtherIncome1,
            relatives_living1: params.values.relativesLiving1,
            relatives_post_code1: params.values.relativesPostCode1,
            relatives_prefecture1: params.values.relativesPrefecture1,
            relatives_municipalities1: params.values.relativesMunicipalities1,
            relatives_town_area1: params.values.relativesTownArea1,
            relatives_other_address1: params.values.relativesOtherAddress1,
            relatives_handicapped1: params.values.relativesHandicapped1,
            relatives_handicapped_detail1:
              params.values.relativesHandicappedDetail1,
            relatives_handicapped_degree1:
              params.values.relativesHandicappedDegree1,
            relativeshandicappedother1:
              params.values.relativesHandicappedOther1,
            relatives_delivery_date1: params.values.relativesDeliveryDate1,
            registration_bereavement1: params.values.relativesBereavement1,
          },
        };
      }

      if (params.values.relativesLastname2) {
        data = {
          ...data,
          properties: {
            ...data.properties,
            relatives_lastname2: params.values.relativesLastname2,
            relatives_lastname_ruby2: params.values.relativesLastnameRuby2,
            relatives_firstname2: params.values.relativesFirstname2,
            relatives_firstname_ruby2: params.values.relativesFirstnameRuby2,
            relatives_birthday2: params.values.relativesBirthday2,
            relatives_gender2: params.values.relativesGender2,
            relatives_relationship2: params.values.relativesRelationship2,
            relatives_salary2: params.values.relativesSalary2,
            relatives_pension2: params.values.relativesPension2,
            relatives_other_income2: params.values.relativesOtherIncome2,
            relatives_living2: params.values.relativesLiving2,
            relatives_post_code2: params.values.relativesPostCode2,
            relatives_prefecture2: params.values.relativesPrefecture2,
            relatives_municipalities2: params.values.relativesMunicipalities2,
            relatives_town_area2: params.values.relativesTownArea2,
            relatives_other_address2: params.values.relativesOtherAddress2,
            relatives_handicapped2: params.values.relativesHandicapped2,
            relatives_handicapped_detail2:
              params.values.relativesHandicappedDetail2,
            relatives_handicapped_degree2:
              params.values.relativesHandicappedDegree2,
            relativeshandicappedother2:
              params.values.relativesHandicappedOther2,
            relatives_delivery_date2: params.values.relativesDeliveryDate2,
            registration_bereavement2: params.values.relativesBereavement2,
          },
        };
      }

      if (params.values.relativesLastname3) {
        data = {
          ...data,
          properties: {
            ...data.properties,
            relatives_lastname3: params.values.relativesLastname3,
            relatives_lastname_ruby3: params.values.relativesLastnameRuby3,
            relatives_firstname3: params.values.relativesFirstname3,
            relatives_firstname_ruby3: params.values.relativesFirstnameRuby3,
            relatives_birthday3: params.values.relativesBirthday3,
            relatives_gender3: params.values.relativesGender3,
            relatives_relationship3: params.values.relativesRelationship3,
            relatives_salary3: params.values.relativesSalary3,
            relatives_pension3: params.values.relativesPension3,
            relatives_other_income3: params.values.relativesOtherIncome3,
            relatives_living3: params.values.relativesLiving3,
            relatives_post_code3: params.values.relativesPostCode3,
            relatives_prefecture3: params.values.relativesPrefecture3,
            relatives_municipalities3: params.values.relativesMunicipalities3,
            relatives_town_area3: params.values.relativesTownArea3,
            relatives_other_address3: params.values.relativesOtherAddress3,
            relatives_handicapped3: params.values.relativesHandicapped3,
            relatives_handicapped_detail3:
              params.values.relativesHandicappedDetail3,
            relatives_handicapped_degree3:
              params.values.relativesHandicappedDegree3,
            relativeshandicappedother3:
              params.values.relativesHandicappedOther3,
            relatives_delivery_date3: params.values.relativesDeliveryDate3,
            registration_bereavement3: params.values.relativesBereavement3,
          },
        };
      }

      if (params.values.relativesLastname4) {
        data = {
          ...data,
          properties: {
            ...data.properties,
            relatives_lastname4: params.values.relativesLastname4,
            relatives_lastname_ruby4: params.values.relativesLastnameRuby4,
            relatives_firstname4: params.values.relativesFirstname4,
            relatives_firstname_ruby4: params.values.relativesFirstnameRuby4,
            relatives_birthday4: params.values.relativesBirthday4,
            relatives_gender4: params.values.relativesGender4,
            relatives_relationship4: params.values.relativesRelationship4,
            relatives_salary4: params.values.relativesSalary4,
            relatives_pension4: params.values.relativesPension4,
            relatives_other_income4: params.values.relativesOtherIncome4,
            relatives_living4: params.values.relativesLiving4,
            relatives_post_code4: params.values.relativesPostCode4,
            relatives_prefecture4: params.values.relativesPrefecture4,
            relatives_municipalities4: params.values.relativesMunicipalities4,
            relatives_town_area4: params.values.relativesTownArea4,
            relatives_other_address4: params.values.relativesOtherAddress4,
            relatives_handicapped4: params.values.relativesHandicapped4,
            relatives_handicapped_detail4:
              params.values.relativesHandicappedDetail4,
            relatives_handicapped_degree4:
              params.values.relativesHandicappedDegree4,
            relativeshandicappedother4:
              params.values.relativesHandicappedOther4,
            relatives_delivery_date4: params.values.relativesDeliveryDate4,
            registration_bereavement4: params.values.relativesBereavement4,
          },
        };
      }

      if (params.values.relativesLastname5) {
        data = {
          ...data,
          properties: {
            ...data.properties,
            relatives_lastname5: params.values.relativesLastname5,
            relatives_lastname_ruby5: params.values.relativesLastnameRuby5,
            relatives_firstname5: params.values.relativesFirstname5,
            relatives_firstname_ruby5: params.values.relativesFirstnameRuby5,
            relatives_birthday5: params.values.relativesBirthday5,
            relatives_gender5: params.values.relativesGender5,
            relatives_relationship5: params.values.relativesRelationship5,
            relatives_salary5: params.values.relativesSalary5,
            relatives_pension5: params.values.relativesPension5,
            relatives_other_income5: params.values.relativesOtherIncome5,
            relatives_living5: params.values.relativesLiving5,
            relatives_post_code5: params.values.relativesPostCode5,
            relatives_prefecture5: params.values.relativesPrefecture5,
            relatives_municipalities5: params.values.relativesMunicipalities5,
            relatives_town_area5: params.values.relativesTownArea5,
            relatives_other_address5: params.values.relativesOtherAddress5,
            relatives_handicapped5: params.values.relativesHandicapped5,
            relatives_handicapped_detail5:
              params.values.relativesHandicappedDetail5,
            relatives_handicapped_degree5:
              params.values.relativesHandicappedDegree5,
            relativeshandicappedother5:
              params.values.relativesHandicappedOther5,
            relatives_delivery_date5: params.values.relativesDeliveryDate5,
            registration_bereavement5: params.values.relativesBereavement5,
          },
        };
      }

      if (params.values.relativesLastname6) {
        data = {
          ...data,
          properties: {
            ...data.properties,
            relatives_lastname6: params.values.relativesLastname6,
            relatives_lastname_ruby6: params.values.relativesLastnameRuby6,
            relatives_firstname6: params.values.relativesFirstname6,
            relatives_firstname_ruby6: params.values.relativesFirstnameRuby6,
            relatives_birthday6: params.values.relativesBirthday6,
            relatives_gender6: params.values.relativesGender6,
            relatives_relationship6: params.values.relativesRelationship6,
            relatives_salary6: params.values.relativesSalary6,
            relatives_pension6: params.values.relativesPension6,
            relatives_other_income6: params.values.relativesOtherIncome6,
            relatives_living6: params.values.relativesLiving6,
            relatives_post_code6: params.values.relativesPostCode6,
            relatives_prefecture6: params.values.relativesPrefecture6,
            relatives_municipalities6: params.values.relativesMunicipalities6,
            relatives_town_area6: params.values.relativesTownArea6,
            relatives_other_address6: params.values.relativesOtherAddress6,
            relatives_handicapped6: params.values.relativesHandicapped6,
            relatives_handicapped_detail6:
              params.values.relativesHandicappedDetail6,
            relatives_handicapped_degree6:
              params.values.relativesHandicappedDegree6,
            relativeshandicappedother6:
              params.values.relativesHandicappedOther6,
            relatives_delivery_date6: params.values.relativesDeliveryDate6,
            registration_bereavement6: params.values.relativesBereavement6,
          },
        };
      }

      if (params.values.relativesLastname7) {
        data = {
          ...data,
          properties: {
            ...data.properties,
            relatives_lastname7: params.values.relativesLastname7,
            relatives_lastname_ruby7: params.values.relativesLastnameRuby7,
            relatives_firstname7: params.values.relativesFirstname7,
            relatives_firstname_ruby7: params.values.relativesFirstnameRuby7,
            relatives_birthday7: params.values.relativesBirthday7,
            relatives_gender7: params.values.relativesGender7,
            relatives_relationship7: params.values.relativesRelationship7,
            relatives_salary7: params.values.relativesSalary7,
            relatives_pension7: params.values.relativesPension7,
            relatives_other_income7: params.values.relativesOtherIncome7,
            relatives_living7: params.values.relativesLiving7,
            relatives_post_code7: params.values.relativesPostCode7,
            relatives_prefecture7: params.values.relativesPrefecture7,
            relatives_municipalities7: params.values.relativesMunicipalities7,
            relatives_town_area7: params.values.relativesTownArea7,
            relatives_other_address7: params.values.relativesOtherAddress7,
            relatives_handicapped7: params.values.relativesHandicapped7,
            relatives_handicapped_detail7:
              params.values.relativesHandicappedDetail7,
            relatives_handicapped_degree7:
              params.values.relativesHandicappedDegree7,
            relativeshandicappedother7:
              params.values.relativesHandicappedOther7,
            relatives_delivery_date7: params.values.relativesDeliveryDate7,
            registration_bereavement7: params.values.relativesBereavement7,
          },
        };
      }

      if (params.values.relativesLastname8) {
        data = {
          ...data,
          properties: {
            ...data.properties,
            relatives_lastname8: params.values.relativesLastname8,
            relatives_lastname_ruby8: params.values.relativesLastnameRuby8,
            relatives_firstname8: params.values.relativesFirstname8,
            relatives_firstname_ruby8: params.values.relativesFirstnameRuby8,
            relatives_birthday8: params.values.relativesBirthday8,
            relatives_gender8: params.values.relativesGender8,
            relatives_relationship8: params.values.relativesRelationship8,
            relatives_salary8: params.values.relativesSalary8,
            relatives_pension8: params.values.relativesPension8,
            relatives_other_income8: params.values.relativesOtherIncome8,
            relatives_living8: params.values.relativesLiving8,
            relatives_post_code8: params.values.relativesPostCode8,
            relatives_prefecture8: params.values.relativesPrefecture8,
            relatives_municipalities8: params.values.relativesMunicipalities8,
            relatives_town_area8: params.values.relativesTownArea8,
            relatives_other_address8: params.values.relativesOtherAddress8,
            relatives_handicapped8: params.values.relativesHandicapped8,
            relatives_handicapped_detail8:
              params.values.relativesHandicappedDetail8,
            relatives_handicapped_degree8:
              params.values.relativesHandicappedDegree8,
            relativeshandicappedother8:
              params.values.relativesHandicappedOther8,
            relatives_delivery_date8: params.values.relativesDeliveryDate8,
            registration_bereavement8: params.values.relativesBereavement8,
          },
        };
      }

      if (params.values.relativesLastname9) {
        data = {
          ...data,
          properties: {
            ...data.properties,
            relatives_lastname9: params.values.relativesLastname9,
            relatives_lastname_ruby9: params.values.relativesLastnameRuby9,
            relatives_firstname9: params.values.relativesFirstname9,
            relatives_firstname_ruby9: params.values.relativesFirstnameRuby9,
            relatives_birthday9: params.values.relativesBirthday9,
            relatives_gender9: params.values.relativesGender9,
            relatives_relationship9: params.values.relativesRelationship9,
            relatives_salary9: params.values.relativesSalary9,
            relatives_pension9: params.values.relativesPension9,
            relatives_other_income9: params.values.relativesOtherIncome9,
            relatives_living9: params.values.relativesLiving9,
            relatives_post_code9: params.values.relativesPostCode9,
            relatives_prefecture9: params.values.relativesPrefecture9,
            relatives_municipalities9: params.values.relativesMunicipalities9,
            relatives_town_area9: params.values.relativesTownArea9,
            relatives_other_address9: params.values.relativesOtherAddress9,
            relatives_handicapped9: params.values.relativesHandicapped9,
            relatives_handicapped_detail9:
              params.values.relativesHandicappedDetail9,
            relatives_handicapped_degree9:
              params.values.relativesHandicappedDegree9,
            relativeshandicappedother9:
              params.values.relativesHandicappedOther9,
            relatives_delivery_date9: params.values.relativesDeliveryDate9,
            registration_bereavement9: params.values.relativesBereavement9,
          },
        };
      }

      if (params.values.relativesLastname10) {
        data = {
          ...data,
          properties: {
            ...data.properties,
            relatives_lastname10: params.values.relativesLastname10,
            relatives_lastname_ruby10: params.values.relativesLastnameRuby10,
            relatives_firstname10: params.values.relativesFirstname10,
            relatives_firstname_ruby10: params.values.relativesFirstnameRuby10,
            relatives_birthday10: params.values.relativesBirthday10,
            relatives_gender10: params.values.relativesGender10,
            relatives_relationship10: params.values.relativesRelationship10,
            relatives_salary10: params.values.relativesSalary10,
            relatives_pension10: params.values.relativesPension10,
            relatives_other_income10: params.values.relativesOtherIncome10,
            relatives_living10: params.values.relativesLiving10,
            relatives_post_code10: params.values.relativesPostCode10,
            relatives_prefecture10: params.values.relativesPrefecture10,
            relatives_municipalities10: params.values.relativesMunicipalities10,
            relatives_town_area10: params.values.relativesTownArea10,
            relatives_other_address10: params.values.relativesOtherAddress10,
            relatives_handicapped10: params.values.relativesHandicapped10,
            relatives_handicapped_detail10:
              params.values.relativesHandicappedDetail10,
            relatives_handicapped_degree10:
              params.values.relativesHandicappedDegree10,
            relativeshandicappedother10:
              params.values.relativesHandicappedOther10,
            relatives_delivery_date10: params.values.relativesDeliveryDate10,
            registration_bereavement10: params.values.relativesBereavement10,
          },
        };
      }

      let response;
      if (params.objectId) {
        response = await axios.patch(
          `${API_SERVER_URL}/line/v1/custom-object/${params.userId}?apikey=${API_KEY}`,
          {
            ...data,
            objectId: params.objectId,
          },
        );
      } else {
        response = await axios.post(
          `${API_SERVER_URL}/line/v1/custom-object/${params.userId}?apikey=${API_KEY}`,
          data,
        );
      }

      const id = response.data.id;
      console.log(id);

      liff
        .sendMessages([
          {
            type: 'text',
            text: `[自動送信：入職手続き]
フォームを登録しました。管理番号は${id}です。`,
          },
        ])
        .then(() => {
          console.log('message sent');
        })
        .catch((err) => {
          console.log(err);
        });

      return response.data;
    } catch (e) {
      console.error(e);
      alert(
        `エラーのため登録に失敗しました。管理者に連絡してください。 LINE ID: ${params.userId} - ${e}`,
      );
      window.location.reload();
    }
  },
);

export const loadEntryContact = createAsyncThunk(
  'loadEntryContact',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/line/v1/custom-object/${params.userId}/${OBJECT_TYPE}?apikey=${API_KEY}`,
    );
    return response.data;
  },
);

export const addressSearch = createAsyncThunk(
  'addressSearch',
  async (params: any) => {
    const response = await axios.post(
      'https://h30.zipserver.jp/app/api/v1/zip',
      {
        zipcode: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Zipserver':
            '67587d495cb97608ca147e195193d1116e9ae18ea27d343c223450330b78d507',
        },
      },
    );
    return response.data;
  },
);

export const retrieveEntry = createAsyncThunk(
  'retrieveEntry',
  async (params: any) => {
    try {
      const response = await axios.get(
        `${API_SERVER_URL}/line/v1/custom-object/${params.userId}/${OBJECT_TYPE}?apikey=${API_KEY}`,
      );
      let records = response.data;
      if (records.length > 0) {
        records = records.filter((record: any) => {
          return record.properties.hs_pipeline === PIPELINE;
        });

        records.sort((a: any, b: any) => {
          if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
            return -1;
          }
          if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
            return 1;
          }
          return 0;
        });
      }
      console.log(records);

      return records.length > 0 ? records[0] : null;
    } catch (e) {
      {
        console.error(e);
        return null;
      }
    }
  },
);

export const retrieveCsEntry = createAsyncThunk(
  'retrieveCsEntry',
  async (params: any) => {
    try {
      const response = await axios.get(
        `${API_SERVER_URL}/line/v1/custom-object/${params.userId}/${OBJECT_TYPE}?apikey=${API_KEY}`,
      );
      let records = response.data;
      if (records.length > 0) {
        records = records.filter((record: any) => {
          return record.properties.hs_pipeline === CS_PIPELINE;
        });

        records.sort((a: any, b: any) => {
          if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
            return -1;
          }
          if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
            return 1;
          }
          return 0;
        });
      }
      console.log(records);

      return records.length > 0 ? records[0] : null;
    } catch (e) {
      {
        console.error(e);
        return null;
      }
    }
  },
);

export interface EntryState {
  entry: any;
  status: 'idle' | 'loading' | 'failed';
  loaded: boolean;
}

const initialState: EntryState = {
  entry: null,
  status: 'idle',
  loaded: false,
};

export const entrySlice = createSlice({
  name: 'entry',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveEntry.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveEntry.fulfilled, (state, action) => {
        state.status = 'idle';
        state.entry = action.payload;
        state.loaded = true;
      })
      .addCase(retrieveCsEntry.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveCsEntry.fulfilled, (state, action) => {
        state.status = 'idle';
        state.entry = action.payload;
        state.loaded = true;
      });
  },
});

export const selectEntry = (state: RootState) => state.entry.entry;
export const isLoaded = (state: RootState) => state.entry.loaded;

export default entrySlice.reducer;
