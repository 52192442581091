import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import liff from '@line/liff';
import { RootState } from '../../app/store';

// eslint-disable-next-line @typescript-eslint/no-var-requires
//const JapaneseHolidays = require('japanese-holidays');

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';
const API_KEY = process.env.REACT_APP_CONTACT_API_KEY || '';
const OBJECT_TYPE = process.env.REACT_APP_ADVANCE_PAY_ENTRY_OBJECT_TYPE || '';
const PIPELINE = process.env.REACT_APP_ADVANCE_PAY_ENTRY_PIPELINE || '';
const PIPELINE_STAGE =
  process.env.REACT_APP_ADVANCE_PAY_ENTRY_PIPELINE_STAGE || '';

export const createAdvancePayEntry = createAsyncThunk(
  'createAdvancePayEntry',
  async (params: any) => {
    try {
      const data: any = {
        objectType: `${OBJECT_TYPE}`,
        associationType: '申請関連_to_コンタクト',

        properties: {
          hs_pipeline: PIPELINE,
          hs_pipeline_stage: PIPELINE_STAGE,
          name: params.values.userName,
          staff_code: params.values.staffCode,
          maekyucontract_staffcode: params.values.staffCode, //スタッフコード
          maekyucontract_name: params.values.userName, //ユーザー名
          maekyu_contract_branch: params.values.userBranch, //支店
          maekyu_contract_appdate: params.values.appDate, //申込日
          maekyu_app_agree: params.values.appAgree, //項目に同意
          maekyu_contract_agree: params.values.contractAgree, //項目に同意
          maekyu_account_agree: params.values.bankAccountAgree, //項目に同意
        },
      };
      //alert(
      //  `${API_SERVER_URL}/line/v1/custom-object/${params.userId}?apikey=${API_KEY}`,
      // );
      const response = await axios.post(
        `${API_SERVER_URL}/line/v1/custom-object/${params.userId}?apikey=${API_KEY}`,
        data,
      );

      const id = response.data.id;
      //console.log(id);
      //console.log(liff);

      liff
        .sendMessages([
          {
            type: 'text',
            text: `[自動応答：前給利用申込]
前給サービス申込を受理しました。管理番号は${id}です。
担当支店へ確認し登録を行います。前給申込サイトへ登録ができましたら別途ご連絡をさせて頂きます。`,
          },
        ])
        .then(() => {
          console.log('message sent');
        })
        .catch((err) => {
          console.log(err);
        });

      setTimeout(() => {
        liff
          .sendMessages([
            {
              type: 'flex',
              altText: '[自動応答:給与講座が未登録の方]',
              contents: {
                type: 'bubble',
                body: {
                  type: 'box',
                  layout: 'vertical',
                  contents: [
                    {
                      type: 'text',
                      text: '-給与口座が未登録の方-\n以下から必ず口座登録を行ってください。\n前給振込までにお口座登録がない場合、お振込みに間に合わない場合がございます。',
                      wrap: true,
                    },
                    {
                      type: 'button',
                      action: {
                        type: 'uri',
                        label: '給与口座の登録はこちら',
                        uri:
                          'https://www.randstad.co.jp/OCLSTF0000.do?uid=' +
                          params.values.staffCode +
                          '&pid=line',
                      },
                      style: 'primary',
                      color: '#6ba5b4',
                      margin: 'md',
                    },
                    {
                      type: 'text',
                      text: '※マイランスタッドログインページが開きます。ログイン後に給与口座登録画面が表示されます。',
                      wrap: true,
                    },
                  ],
                },
              },
            },
          ])
          .then(() => {
            console.log('message sent');
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);

      return response.data;
    } catch (e) {
      console.error(e);
      alert(
        `エラーのため登録に失敗しました。管理者に連絡してください。 LINE ID: ${params.userId} - ${e}`,
      );
      window.location.reload();
    }
  },
);

export const modifyAdvancePayEntry = createAsyncThunk(
  'modifyAdvancePayEntry',

  async (params: any) => {
    try {
      const data: any = {
        objectType: `${OBJECT_TYPE}`,
        associationType: '申請関連_to_コンタクト',

        properties: {
          hs_pipeline: PIPELINE,
          hs_pipeline_stage: PIPELINE_STAGE,
          name: params.values.userName,
          staff_code: params.values.staffCode,
          maekyucontract_staffcode: params.values.staffCode, //スタッフコード
          maekyucontract_name: params.values.userName, //ユーザー名
          maekyu_contract_branch: params.values.userBranch, //支店
          maekyu_contract_appdate: params.values.appDate, //申込日
          maekyu_app_agree: params.values.appAgree, //項目に同意
          maekyu_contract_agree: params.values.contractAgree, //項目に同意
          maekyu_account_agree: params.values.bankAccountAgree, //項目に同意
        },
      };

      const response = await axios.patch(
        `${API_SERVER_URL}/line/v1/custom-object/${params.userId}?apikey=${API_KEY}`,
        {
          ...data,
          objectId: params.objectId,
        },
      );

      const id = response.data.id;

      liff
        .sendMessages([
          {
            type: 'text',
            text: `再申請ありがとうございます。
修正内容を確認後、改めてLINEより受領完了のメッセージをお送りいたしますので今しばらくお待ちください。`,
          },
        ])
        .then(() => {
          console.log('message sent');
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.error(e);
      alert(
        `エラーのため登録に失敗しました。管理者に連絡してください。 LINE ID: ${params.userId} - ${e}`,
      );
      window.location.reload();
    }
  },
);
export const retrieveAdvancePayEntry = createAsyncThunk(
  'retrieveAdvancePayEntry',
  async (params: any) => {
    try {
      const response = await axios.get(
        `${API_SERVER_URL}/line/v1/custom-object/${params.userId}/${OBJECT_TYPE}?apikey=${API_KEY}`,
      );
      let records = response.data;

      if (records.length > 0) {
        records = records.filter((record: any) => {
          return record.properties.hs_pipeline === PIPELINE;
        });
        /*
        records.sort((a: any, b: any) => {
          if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
            return -1;
          }
          if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
            return 1;
          }
          return 0;
        });
        */
      }
      //console.log(records);
      return records.length > 0 ? records[0] : null;
    } catch (e) {
      {
        console.error(e);
        return null;
      }
    }
  },
);

export interface AdvancePayEntryState {
  status: 'idle' | 'loading' | 'failed';
  created: boolean;
  entry: any;
  edit: boolean;
}

const initialState: AdvancePayEntryState = {
  status: 'idle',
  created: false,
  entry: null,
  edit: false,
};

export const advancePayEntrySlice = createSlice({
  name: 'advancePayEntry',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAdvancePayEntry.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAdvancePayEntry.fulfilled, (state) => {
        state.status = 'idle';
        state.created = true;
      })
      .addCase(modifyAdvancePayEntry.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(modifyAdvancePayEntry.fulfilled, (state) => {
        state.status = 'idle';
        state.created = true;
      })
      .addCase(retrieveAdvancePayEntry.pending, (state, action) => {
        state.status = 'loading';
        state.entry = action.payload;
        state.edit = action.payload != null ? true : false;
      })
      .addCase(retrieveAdvancePayEntry.fulfilled, (state, action) => {
        state.status = 'idle';
        state.entry = action.payload;
        state.edit = action.payload ? true : false;
      });
  },
});

export const isCreated = (state: RootState) => state.advancePayEntry.created;
export const selectAdvancePayEntry = (state: RootState) =>
  state.advancePayEntry.entry;
export const isEdit = (state: RootState) => state.advancePayEntry.edit;

export default advancePayEntrySlice.reducer;
