import * as React from 'react';
import { useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';

import liff from '@line/liff';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { createAdvancePay } from '../features/advancePay/advancePaySlice';
import { userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import { jsPDF } from 'jspdf';

// @formatter:off
const steps = ['1', '2', '3', '4', '5', '6', '7'];

class AdvancePayFormProps {
  profile: any;
  created: boolean | undefined;
  branch: string | undefined;
}

const AdvancePayForm: React.FC<AdvancePayFormProps> = ({
  profile,
  created,
  branch,
}) => {
  // interface formName {
  //   lastname: string;
  //   firstname: string;
  //   staffCode: string;
  //   advancePayType: string;
  //   advancePayBranch: string;
  // }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      lastname: profile.lastname,
      firstname: profile.firstname,
      staffCode: profile.staffCode,
      ready: 'yes',
      advancePayType: 'デジシート',
      advancePayBranch: profile.advancePayBranch || '',
      advancePayAccountChange: '',
      advancePayFileUrl: '',
      advancePayPdfFileUrl: '',
      advancePayFileUrl2: '',
      advancePayPdfFileUrl2: '',
    },
    errors: {},
    touched: {},
  });

  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const dispatch = useAppDispatch();
  const [creating, setCreating] = React.useState(false);
  const submit = () => {
    setCreating(true);
    dispatch(
      createAdvancePay({
        userId: userId(),
        values: formState.values,
      }),
    );
  };

  // console.log(created);
  if (created && activeStep !== 7) {
    setCreating(false);
    setActiveStep(7);
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const [open3, setOpen3] = React.useState(false);
  const handleClose3 = () => {
    setOpen3(false);
  };
  const [open4, setOpen4] = React.useState(false);
  const handleClose4 = () => {
    setOpen4(false);
  };
  const [open5, setOpen5] = React.useState(false);
  const handleClose5 = () => {
    setOpen5(false);
  };
  const [open6, setOpen6] = React.useState(false);
  const handleClose6 = () => {
    setOpen6(false);
  };
  const [open7, setOpen7] = React.useState(false);
  const handleClose7 = () => {
    setOpen7(false);
  };

  const handleNext = () => {
    console.log(activeStep);
    let specialAdd = 0;
    if (activeStep === 0) {
      if (
        formState.values.advancePayType === 'FAX-OCRシート' ||
        formState.values.advancePayType === 'その他タイムカード'
      ) {
        specialAdd = 1;
      }
    }

    if (activeStep === 1) {
      if (formState.values.ready === 'no') {
        setOpen(true);
        return;
      }
    }

    if (activeStep === 2) {
      if (
        formState.values.advancePayType !== 'FAX-OCRシート' &&
        formState.values.advancePayType !== 'その他タイムカード'
      ) {
        specialAdd = 1;
      }
    }

    if (activeStep === 2) {
      if (!formState.values.staffCode) {
        setOpen4(true);
        return;
      } else if (String(formState.values.staffCode).length !== 8) {
        setOpen5(true);
        return;
      }
    }

    if (activeStep === 3) {
      if (!formState.values.advancePayFileUrl) {
        setOpen2(true);
        return;
      }
    }

    if (activeStep === 4) {
      if (!formState.values.advancePayBranch) {
        setOpen3(true);
        return;
      }
    }

    if (activeStep === 5) {
      if (!formState.values.advancePayAccountChange) {
        setOpen7(true);
        return;
      }
    }

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1 + specialAdd;
    setActiveStep(newActiveStep);
    window.scrollTo({
      top: 0,
    });
  };
  const handleBack = () => {
    console.log(activeStep);
    let specialAdd = 0;
    if (activeStep === 2) {
      if (
        formState.values.advancePayType === 'FAX-OCRシート' ||
        formState.values.advancePayType === 'その他タイムカード'
      ) {
        specialAdd = 1;
      }
    }

    if (activeStep === 4) {
      if (
        formState.values.advancePayType !== 'FAX-OCRシート' &&
        formState.values.advancePayType !== 'その他タイムカード'
      ) {
        specialAdd = 1;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1 - specialAdd);
    window.scrollTo({
      top: 0,
    });
  };

  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);

  const onChangeFile = (event: any, seq = 0) => {
    const data = new FormData();

    const file = event.target.files[0];
    data.append('file', file);

    if (
      file.type !== 'application/pdf' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png'
    ) {
      setOpen6(true);
      return;
    }

    setCreating(true);

    axios
      .post(`https://storage-staging.littlehelp.co.jp/5856445/upload`, data)
      .then((res) => {
        console.log(res.data.url);
        handleChange(
          {
            target: {
              name: seq === 2 ? 'advancePayFileUrl2' : 'advancePayFileUrl',
            },
          },
          seq === 2 ? 'advancePayFileUrl2' : 'advancePayFileUrl',
          res.data.url,
        );

        setCreating(false);

        if (String(file.type).includes('pdf')) {
          handleChange(
            {
              target: {
                name:
                  seq === 2 ? 'advancePayPdfFileUrl2' : 'advancePayPdfFileUrl',
              },
            },
            seq === 2 ? 'advancePayPdfFileUrl2' : 'advancePayPdfFileUrl',
            res.data.url,
          );
          return;
        }

        try {
          const doc = new jsPDF();
          const img = new Image();
          img.src = res.data.url;

          // This is the magic parameter for smartphones...
          doc.addImage(img, 'JPEG', 10, -180, 250, 190, undefined, 'NONE', 270);

          const pdf: Blob = doc.output('blob');

          const data = new FormData();
          data.append('file', pdf, 'advancePay.pdf');

          axios
            .post(
              `https://storage-staging.littlehelp.co.jp/5856445/upload`,
              data,
            )
            .then((res) => {
              console.log(res.data.url);
              handleChange(
                {
                  target: {
                    name:
                      seq === 2
                        ? 'advancePayPdfFileUrl2'
                        : 'advancePayPdfFileUrl',
                  },
                },
                seq === 2 ? 'advancePayPdfFileUrl2' : 'advancePayPdfFileUrl',
                res.data.url,
              );
            });
        } catch (e) {
          console.error(e);

          // Try again...
          try {
            const doc = new jsPDF();
            const img = new Image();
            img.src = res.data.url;

            // This is the magic parameter for smartphones...
            doc.addImage(
              img,
              'JPEG',
              10,
              -180,
              250,
              190,
              undefined,
              'NONE',
              270,
            );

            const pdf: Blob = doc.output('blob');

            const data = new FormData();
            data.append('file', pdf, 'advancePay.pdf');

            axios
              .post(
                `https://storage-staging.littlehelp.co.jp/5856445/upload`,
                data,
              )
              .then((res) => {
                console.log(res.data.url);
                handleChange(
                  {
                    target: {
                      name:
                        seq === 2
                          ? 'advancePayPdfFileUrl2'
                          : 'advancePayPdfFileUrl',
                    },
                  },
                  seq === 2 ? 'advancePayPdfFileUrl2' : 'advancePayPdfFileUrl',
                  res.data.url,
                );
              });
          } catch (e) {
            console.error(e);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setCreating(false);
      });
  };

  const branches =
    branch === 'dr'
      ? [
          '',
          'DR北関東支店（DR小山課/DR高崎課）',
          'DR東京・千葉支店（DR新宿課/DR船橋課）',
          'DR埼玉支店（DRさいたま課/DR所沢課）',
          'DR神奈川支店（DR横浜課/DR厚木課）',
          'DR名阪支店（DR名古屋課/DR大阪課）',
        ]
      : [
          '',
          '札幌支店',
          '千歳支店',
          '米沢支店',
          '仙台支店',
          '郡山支店',
          '新潟支店',
          '長岡支店',
          '久喜支店',
          '水戸支店',
          'つくば支店',
          '筑西支店',
          '古河支店',
          '栃木第1支店（那須課/宇都宮1課/宇都宮2課）',
          '栃木第2支店（情報の森課/小山課）',
          '那須ファクトリー支店',
          '宇都宮ファクトリー支店',
          '清原支店',
          '小山ファクトリー支店',
          '栃木GS支店',
          '足利支店',
          '熊谷支店',
          '高崎支店',
          '伊勢崎支店',
          '日本橋支店',
          '首都圏金融支店',
          '新宿支店',
          '横浜支店',
          '厚木支店',
          '立川支店',
          '東京ベイ支店',
          'さいたま支店',
          '千葉支店',
          '船橋支店',
          '所沢支店',
          '楽天習志野',
          '静岡支店',
          '名古屋支店',
          '四日市支店',
          '京都支店',
          '大阪支店',
          '難波支店',
          '神戸支店',
          '高松支店',
          '松山支店',
          '広島支店',
          '北九州支店',
          '福岡支店',
          '熊本支店',
          '宮崎支店',
          '鹿児島支店',
          'REG-A',
          'エンジニア事業部 名古屋支店',
          'エンジニア事業部 大阪支店',
          'エンジニア事業部 福岡支店',
          '試験監督',
        ];

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            {activeStep === 0 ? '前給の申請' : ''}
            {activeStep === 1 ? '勤怠入力の確認' : ''}
            {activeStep === 2 ? '申請者の確認' : ''}
            {activeStep === 3 ? '画像のアップロード' : ''}
            {activeStep === 4 ? '支店/事業所名の選択' : ''}
            {activeStep === 5 ? '振込口座の確認' : ''}
            {activeStep === 6 ? '確認画面' : ''}
            {activeStep === 7 ? '受付完了' : ''}
          </Typography>
        </Toolbar>
      </AppBar>
      {creating ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ m: 2 }}>
          {/*<Stepper*/}
          {/*  activeStep={activeStep}*/}
          {/*  alternativeLabel*/}
          {/*  sx={{ display: activeStep === 0 ? 'none' : '' }}*/}
          {/*>*/}
          {/*  {steps.map((label) => {*/}
          {/*    const stepProps: { completed?: boolean } = {};*/}
          {/*    const labelProps: {*/}
          {/*      optional?: React.ReactNode;*/}
          {/*    } = {};*/}
          {/*    return (*/}
          {/*      <Step key={label} {...stepProps}>*/}
          {/*        <StepLabel {...labelProps} />*/}
          {/*      </Step>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</Stepper>*/}
          {activeStep === 0 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    color: '#696969',
                    fontSize: '13px',
                    marginBottom: '1em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                  }}
                >
                  {moment().format('YYYY年M月D日')}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src="/randstad-logo.png" alt="logo" width="80%" />
                </div>
                <br />
                ※前給は利用可能金額や振込手数料がかかることなどにあらかじめご了承のうえ、手続きを進めてください。（詳しくは資料集→クイックガイドを参照）
                <br />
                <br />
                <span
                  style={{
                    color: '#e25969',
                    fontWeight: 'bold',
                  }}
                >
                  平日午前10時
                </span>
                までに申請ください。
                <br />
                （申請締め切りや振込日は前給カレンダーに準じますのでご了承ください）
                <br />
                <br />
                勤務先で使用している勤怠提出方法を選択ください。
                <br />
                <br />
                <div>
                  <FormControl sx={{ ml: 2, width: '95%' }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.advancePayType}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'advancePayType',
                          event.target.value,
                        );

                        if (
                          event.target.value === 'デジシート' ||
                          event.target.value === 'e-staffing (e-Time Card)' ||
                          event.target.value === 'HRステーション'
                        ) {
                          handleChange(
                            {
                              target: {
                                name: 'advancePayFileUrl',
                              },
                            },
                            'advancePayFileUrl',
                            null,
                          );
                        }
                      }}
                    >
                      <FormControlLabel
                        value="デジシート"
                        control={<Radio />}
                        label="デジシート"
                      />
                      <FormControlLabel
                        value="e-staffing (e-Time Card)"
                        control={<Radio />}
                        label="e-staffing (e-Time Card)"
                      />
                      <FormControlLabel
                        value="HRステーション"
                        control={<Radio />}
                        label="HRステーション"
                      />
                      <FormControlLabel
                        value="FAX-OCRシート"
                        control={<Radio />}
                        label="FAX-OCRシート"
                      />
                      <FormControlLabel
                        value="その他タイムカード"
                        control={<Radio />}
                        label="その他タイムカード（＋@タイムシート、及び上記以外の勤怠カードなど）"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <br />
                ※FAX-OCRシート、その他タイムカードを選択した場合は次の画面でその勤怠カードの写真の添付が必要です。
                <br />
                <br />
                <span
                  style={{
                    color: '#e25969',
                    fontWeight: 'bold',
                  }}
                >
                  ※不明点や問合せについては担当の支店もしくはコンサルタントへご連絡ください。
                </span>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 1 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                申請前に、必ずご自身の該当の勤怠の入力および申請が完了しているかどうかを確認ください。
                <br />
                <br />
                勤怠の入力が完了していない場合は前給の処理ができない、もしくは確認のため遅れてしまう可能性がありますのでご了承ください。
                <br />
                <br />
                勤怠の入力は完了していますか？
                <br />
                <br />
                <div>
                  <FormControl sx={{ ml: 2, width: '80%' }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.ready}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(event, 'ready', event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="はい"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="いいえ"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 2 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                確認のため、申請者ご自身のスタッフコード、および氏名に間違えがないかどうか確認ください。
                <br />
                <br />
                <div>
                  <TextField
                    id="staff-code"
                    variant="standard"
                    label="スタッフコード"
                    type="tel"
                    placeholder="00000000"
                    value={formState.values.staffCode}
                    inputProps={{ maxLength: '8' }}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'staffCode', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '81%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    label="姓"
                    variant="standard"
                    value={formState.values.lastname}
                    onChange={(event) => {
                      handleChange(event, 'lastname', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                    placeholder="山田"
                  />
                  <TextField
                    id="standard-basic"
                    label="名"
                    variant="standard"
                    value={formState.values.firstname}
                    onChange={(event) => {
                      handleChange(event, 'firstname', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                    placeholder="太郎"
                  />
                </div>
                <br />
                ※間違いがある場合は修正後に次へ進んでください。
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 3 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                手元にあるFAX-OCRシート、その他タイムカードを写真に取り、下記のアップロードより添付ください。
                <br />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '1em',
                  }}
                >
                  <Button
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      fileInputRef.current.click();
                    }}
                    style={{
                      width: '100%',
                    }}
                  >
                    {formState.values.advancePayFileUrl ? (
                      <Avatar
                        alt="Icon"
                        src={formState.values.advancePayFileUrl}
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: 'unset',
                        }}
                      />
                    ) : (
                      <Button
                        color="inherit"
                        variant={'contained'}
                        sx={{
                          marginTop: 1,
                          width: '100%',
                          backgroundColor: '#1d6ad4',
                          color: '#fff',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            width: '100%',
                          }}
                        >
                          {'画像をアップロードする'}
                        </span>
                      </Button>
                    )}
                  </Button>
                </div>
                <div
                  style={{
                    display: formState.values.advancePayFileUrl
                      ? 'flex'
                      : 'none',
                    justifyContent: 'center',
                    padding: '1em',
                  }}
                >
                  <Button
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      fileInputRef2.current.click();
                    }}
                    style={{
                      width: '100%',
                    }}
                  >
                    {formState.values.advancePayFileUrl2 ? (
                      <Avatar
                        alt="Icon"
                        src={formState.values.advancePayFileUrl2}
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: 'unset',
                        }}
                      />
                    ) : (
                      <Button
                        color="inherit"
                        variant={'contained'}
                        sx={{
                          marginTop: 1,
                          width: '100%',
                          backgroundColor: '#1d6ad4',
                          color: '#fff',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            width: '100%',
                          }}
                        >
                          {'画像を追加する'}
                        </span>
                      </Button>
                    )}
                  </Button>
                </div>
              </div>
              <div
                style={{
                  padding: '1em',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      fontSize: '1.1em',
                      fontWeight: 'bold',
                      paddingRight: '2em',
                    }}
                  >
                    ＜写真の撮り方＞
                  </div>
                  <div>
                    <img
                      src="/images/advance-pay-upload-instraction.jpg"
                      alt="instraction"
                      width={90}
                    />
                  </div>
                </div>
                <div>
                  カメラの枠にタイムシートの紙全体が写るように撮影ください。
                  <br />
                  <br />
                  ・氏名、スタッフコード、年月など申請者の情報が写っていること
                  <br />
                  ・勤務時間、休憩時間が写っていること
                  <br />
                  ・承認欄がある場合は承認印が写っていること
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 4 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                あなたを担当している支店/事業所名を選択ください。
                <br />
                <br />
                <div
                  style={{
                    display: branch === 'inhouse' ? 'none' : '',
                  }}
                >
                  <InputLabel
                    sx={{ ml: 2, mt: 2, color: '#0f1941', fontSize: '1em' }}
                    id="jobCategory-label"
                  >
                    {'担当支店'}
                  </InputLabel>
                  <FormControl
                    sx={{ ml: 2, mt: 1, mr: 2, mb: 2, width: '80%' }}
                  >
                    <Select
                      labelId="jobCategory-label"
                      id="jobCategory-select"
                      variant="standard"
                      value={formState.values.advancePayBranch}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'advancePayBranch',
                          event.target.value,
                        );
                      }}
                    >
                      {branches.map((branch) => {
                        return <MenuItem value={branch}>{branch}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    display: branch === 'inhouse' ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{ ml: 2, mt: 2, color: '#0f1941', fontSize: '1em' }}
                    id="jobCategory-label"
                  >
                    {'担当支店'}
                  </InputLabel>
                  <FormControl
                    sx={{ ml: 2, mt: 1, mr: 2, mb: 2, width: '80%' }}
                  >
                    <Select
                      labelId="jobCategory-label"
                      id="jobCategory-select"
                      variant="standard"
                      value={formState.values.advancePayBranch}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'advancePayBranch',
                          event.target.value,
                        );
                      }}
                    >
                      <MenuItem value={''} />
                      <MenuItem value={'宮城ニコン（戸倉）'}>
                        宮城ニコン（戸倉）
                      </MenuItem>
                      <MenuItem value={'宮城ニコン（蔵王）'}>
                        宮城ニコン（蔵王）
                      </MenuItem>
                      <MenuItem value={'東北電力（契約センター）'}>
                        東北電力（契約センター）
                      </MenuItem>
                      <MenuItem value={'東北電力（料金事務センター）'}>
                        東北電力（料金事務センター）
                      </MenuItem>
                      <MenuItem value={'プルデンシャル生命'}>
                        プルデンシャル生命
                      </MenuItem>
                      <MenuItem value={'NTT'}>NTT</MenuItem>
                      <MenuItem value={'仙台ニコン'}>仙台ニコン</MenuItem>
                      <MenuItem
                        style={{ fontSize: '.9em' }}
                        value={'マーレエンジンコンポーネンツジャパン山形工場'}
                      >
                        マーレエンジンコンポーネンツジャパン山形工場
                      </MenuItem>
                      <MenuItem value={'ディーアンドエム事業所'}>
                        ディーアンドエム事業所
                      </MenuItem>
                      <MenuItem value={'リコー事業所'}>リコー事業所</MenuItem>
                      <MenuItem value={'電興製作所'}>電興製作所</MenuItem>
                      <MenuItem value={'JAうつのみや'}>JAうつのみや</MenuItem>
                      <MenuItem value={'ミツトヨ'}>ミツトヨ</MenuItem>
                      <MenuItem value={'パナソニック住宅設備'}>
                        パナソニック住宅設備
                      </MenuItem>
                      <MenuItem value={'BOSCH'}>BOSCH</MenuItem>
                      <MenuItem value={'三菱重工パワー精密鋳造事業所'}>
                        三菱重工パワー精密鋳造事業所
                      </MenuItem>
                      <MenuItem value={'SII'}>SII</MenuItem>
                      <MenuItem value={'富士通'}>富士通</MenuItem>
                      <MenuItem value={'ゼブラ'}>ゼブラ</MenuItem>
                      <MenuItem value={'ソニーMS'}>ソニーMS</MenuItem>
                      <MenuItem value={'日立GLS'}>日立GLS</MenuItem>
                      <MenuItem value={'モノタロウ事業所'}>
                        モノタロウ事業所
                      </MenuItem>
                      <MenuItem value={'デンソーテン'}>デンソーテン</MenuItem>
                      <MenuItem value={'太陽誘電'}>太陽誘電</MenuItem>
                      <MenuItem value={'ポッカサッポロ'}>
                        ポッカサッポロ
                      </MenuItem>
                      <MenuItem value={'パナソニック大泉'}>
                        パナソニック大泉
                      </MenuItem>
                      <MenuItem value={'TOPPAN　新潟'}>TOPPAN　新潟</MenuItem>
                      <MenuItem value={'サンエス工業事業所'}>
                        サンエス工業事業所
                      </MenuItem>
                      <MenuItem value={'デンカ青海新潟事業所'}>
                        デンカ青海新潟事業所
                      </MenuItem>
                      <MenuItem value={'ZACROSオフィス'}>
                        ZACROSオフィス
                      </MenuItem>
                      <MenuItem value={'パイロット平塚'}>
                        パイロット平塚
                      </MenuItem>
                      <MenuItem value={'SONY DADC-Y'}>SONY DADC-Y</MenuItem>
                      <MenuItem value={'新潟太陽誘電事業所'}>
                        新潟太陽誘電事業所
                      </MenuItem>
                      <MenuItem value={'RYOBI'}>RYOBI</MenuItem>
                      <MenuItem
                        style={{ fontSize: '.9em' }}
                        value={'シャープディスプレイカラーフィルター久居事業所'}
                      >
                        シャープディスプレイカラーフィルター久居事業所
                      </MenuItem>
                      <MenuItem value={'凸版印刷（亀山）'}>
                        凸版印刷（亀山）
                      </MenuItem>
                      <MenuItem value={'エルソルプロダクツ'}>
                        エルソルプロダクツ
                      </MenuItem>
                      <MenuItem value={'坂角'}>坂角</MenuItem>
                      <MenuItem
                        value={'マーレエンジンコンポーネンツジャパン鶴岡工場'}
                      >
                        マーレエンジンコンポーネンツジャパン鶴岡工場
                      </MenuItem>
                      <MenuItem value={'キヤノンメディカルシステムズ'}>
                        キヤノンメディカルシステムズ
                      </MenuItem>
                      <MenuItem value={'パナソニック'}>パナソニック</MenuItem>
                      <MenuItem value={'P&G（高崎）'}>P&G（高崎）</MenuItem>
                      <MenuItem value={'P&G（藤岡）'}>P&G（藤岡）</MenuItem>
                      <MenuItem value={'PILOT（伊勢崎）'}>
                        PILOT（伊勢崎）
                      </MenuItem>
                      <MenuItem value={'PILOT（TCR）'}>PILOT（TCR）</MenuItem>
                      <MenuItem value={'コダック'}>コダック</MenuItem>
                      <MenuItem value={'タワーパートナーズセミコンダクター'}>
                        タワーパートナーズセミコンダクター
                      </MenuItem>
                      <MenuItem value={'トッパンエレプロ熊本'}>
                        トッパンエレプロ熊本
                      </MenuItem>
                      <MenuItem
                        value={'パイロットコーポレーション東松山オフィス'}
                      >
                        パイロットコーポレーション東松山オフィス
                      </MenuItem>
                      <MenuItem value={'宮崎日機装オフィス'}>
                        宮崎日機装オフィス
                      </MenuItem>
                      <MenuItem value={'楽天多摩'}>楽天多摩</MenuItem>
                      <MenuItem value={'楽天市川'}>楽天市川</MenuItem>
                      <MenuItem value={'楽天大阪'}>楽天大阪</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <br />
                ※担当支店が変更になった場合は上記も選びなおしてください。
              </div>
              {branch !== 'dr' && branch !== 'inhouse' ? (
                <div
                  style={{
                    color: '#e25969',
                    padding: '1em',
                  }}
                >
                  <span
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    ※ドライバー業務、自動車運転業務に従事する方はこのアカウントからは申請できません。
                  </span>
                  <br />
                  <span
                    style={{
                      display: branch === 'kansai' ? 'none' : '',
                    }}
                  >
                    下記のリンク先より申請をやり直してください。
                    <a
                      href="https://lin.ee/PNU6mCR"
                      style={{
                        color: '#1d6ad4',
                        fontWeight: 'bold',
                      }}
                    >
                      こちら
                    </a>
                  </span>
                </div>
              ) : null}
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 5 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div>
                  <br />
                  最後に、前給の振込口座に関する確認です。
                  <br />
                  <br />
                  前回の申請から本日までに、給与のご口座の変更はありましたか？
                  <br />
                </div>
                <br />
                <br />
                <FormControl sx={{ ml: 2, width: '80%' }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={formState.values.advancePayAccountChange}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(
                        event,
                        'advancePayAccountChange',
                        event.target.value,
                      );
                    }}
                  >
                    <FormControlLabel
                      value="あり"
                      control={<Radio />}
                      label="あり"
                    />
                    <FormControlLabel
                      value="なし"
                      control={<Radio />}
                      label="なし"
                    />
                  </RadioGroup>
                </FormControl>
                <br />
                <br />
                <div>
                  <span
                    style={{
                      color: '#e25969',
                      padding: '1em',
                    }}
                  >
                    ※変更ありと回答いただいた方のみ登録情報を照会し、処理時点で変更が確認できた場合のみ変更口座に振込みをさせていただきますので予めご了承ください。
                    <br />
                    <br />
                    ※登録口座を確認したい場合はお手数ですが担当支店へお問合せください。
                  </span>
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 6 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                最後に申請内容を確認ください。
                <br />
                <br />
                申請者情報
                <br />
                スタッフコード： <b>{formState.values.staffCode}</b>
                <br />
                姓： <b>{formState.values.lastname}</b>
                {'　'}名：<b>{formState.values.firstname}</b>
                <br />
                <br />
                提出する勤怠：<b>{formState.values.advancePayType}</b>
                <br />
                担当支店： <b>{formState.values.advancePayBranch}</b>
                {formState.values.advancePayFileUrl ? (
                  <div>
                    <br />
                    <Avatar
                      alt="Icon"
                      src={formState.values.advancePayFileUrl}
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 'unset',
                      }}
                    />
                  </div>
                ) : null}
                {formState.values.advancePayFileUrl2 ? (
                  <div>
                    <br />
                    <Avatar
                      alt="Icon"
                      src={formState.values.advancePayFileUrl2}
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 'unset',
                      }}
                    />
                  </div>
                ) : null}
                <br />
                口座の変更：
                <b>{formState.values.advancePayAccountChange}</b>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 7 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                前給の申請を受付ました。お手続きありがとうございます。
                <br />
                <br />
                申請日時： <b>{moment().format('M月D日 HH時mm分')}</b>
                <br />
                <br />
                なお、申請の受付については上記申請日時を基準に処理を進めてまいります。
                <br />
                <br />
                当日の受付時間を過ぎている場合は翌日受付での処理となりますのでご了承ください。
                <br />
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          <Grid
            item
            xs={7}
            style={{
              textAlign: 'center',
            }}
          >
            <AppBar
              position="fixed"
              color="primary"
              sx={{
                top: 'auto',
                bottom: -2,
                backgroundColor: '#1d6ad4',
              }}
            >
              <Grid
                container
                style={{
                  height: 55,
                }}
              >
                {activeStep === 7 ? (
                  <React.Fragment>
                    <Grid
                      item
                      sx={{ width: '100%' }}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="next key"
                        sx={{
                          marginTop: 1,
                          width: '100%',
                        }}
                        /*disabled={applied}*/
                        onClick={() => {
                          liff.closeWindow();
                        }}
                      >
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          {'完了'}
                        </span>
                      </Button>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid
                      item
                      xs={6}
                      style={{
                        backgroundColor: '#d9d9d9',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="back key"
                        sx={{
                          backgroundColor: '#d9d9d9',
                          marginTop: 1,
                          width: '100%',
                          color: '#1d6ad4',
                        }}
                        disabled={activeStep === 0 || activeStep === 7}
                        onClick={handleBack}
                      >
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          前へ
                        </span>
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="next key"
                        sx={{
                          marginTop: 1,
                          width: '100%',
                        }}
                        onClick={
                          activeStep === 6
                            ? submit
                            : activeStep === 7
                            ? () => {
                                liff.closeWindow();
                              }
                            : handleNext
                        }
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            width: '50%',
                          }}
                        >
                          {activeStep === 6
                            ? '申請する'
                            : activeStep === 7
                            ? '完了'
                            : '次へ'}
                        </span>
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              {/*</Toolbar>*/}
            </AppBar>
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                恐れ入りますがデジシートもしくはe-staffingへ勤怠を入力後に再度申請を進めてください。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <input
            ref={fileInputRef}
            type="file"
            accept="image/jpg,image/png,image/jpeg,application/pdf"
            onChange={(event) => {
              onChangeFile(event);
            }}
            style={{
              display: 'none',
            }}
          />

          <input
            ref={fileInputRef2}
            type="file"
            accept="image/jpg,image/png,image/jpeg,application/pdf"
            onChange={(event) => {
              onChangeFile(event, 2);
            }}
            style={{
              display: 'none',
            }}
          />

          <Dialog
            open={open2}
            onClose={handleClose2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                画像がアップロードされていません。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose2} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open3}
            onClose={handleClose3}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                担当支店が選択されていません。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose3} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open4}
            onClose={handleClose4}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                スタッフコードが入力されていません。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose4} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open5}
            onClose={handleClose5}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  color: '#e25969',
                }}
              >
                スタッフコードは8桁での入力をお願いします。
                <br />
                （8桁のスタッフコードを知らない場合は担当支店かコンサルタントにご確認ください）
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose5} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open6}
            onClose={handleClose6}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  color: '#e25969',
                }}
              >
                アップロードできるファイル形式は「jpg」「jpeg」「png」または「pdf」です。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose6} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open7}
            onClose={handleClose7}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                給与の振込口座に変更について回答してください。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose7} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </span>
  );
};

export default AdvancePayForm;
