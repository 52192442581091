import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  isLoaded,
  retrieveProfile,
  selectProfile,
} from '../features/profile/profileSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import liff from '@line/liff';
import TopForm from '../components/topForm';

export default function Top() {
  const dispatch = useAppDispatch();
  const profile: any = useAppSelector(selectProfile);
  const loaded = useAppSelector(isLoaded);

  useEffect(() => {
    document.title = '入職書類・社会保険・雇用保険手続き';

    if (!loaded) {
      dispatch(
        retrieveProfile({
          userId: userId(),
        }),
      );
    }
  }, [loaded]);

  return (
    <span>
      {!profile ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TopForm profile={profile} />
      )}
    </span>
  );
}
