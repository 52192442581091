import * as React from 'react';
import { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import liff from '@line/liff';
import { userId } from '../utils/liff';
import {
  ContactObject_ContractRenewal,
  answerContractRenewal,
  selectContractRenewStatus,
} from '../features/contractRenewal/contractRenewalSlice';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../app/hooks';

type ContractRenewalFormProps = {
  contractRenewal: any;
};

const ContractRenewalForm: React.FC<ContractRenewalFormProps> = ({
  contractRenewal,
}) => {
  const [formState, setFormState] =
    useState<ContactObject_ContractRenewal>(contractRenewal);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const contractRenewStatus = useAppSelector(selectContractRenewStatus);
  const dispatch = useAppDispatch();

  // 表示している質問のフィールド
  const displayFields = new Set<keyof ContactObject_ContractRenewal>();

  const handleChange = (values: ContactObject_ContractRenewal) => {
    // 次回の契約更新
    if (values.line_contract_renew_preference) {
      setFormState(values);
    }
    // 理由、今後の進め方
    else if (
      values.line_contaract_renew_procedure ||
      values.line_contaract_renew_problem
    ) {
      setFormState((formState) => ({
        line_contract_renew_preference:
          formState.line_contract_renew_preference,
        ...values,
      }));
    }
    // 一次連絡手段
    else if (values.line_contaract_contact_preference) {
      setFormState((formState) => ({
        line_contract_renew_preference:
          formState.line_contract_renew_preference,
        line_contaract_renew_procedure:
          formState.line_contaract_renew_procedure,
        line_contaract_renew_problem: formState.line_contaract_renew_problem,
        ...values,
      }));
    } else {
      setFormState((formState) => ({ ...formState, ...values }));
    }
  };

  const handleSubmit = (values: ContactObject_ContractRenewal) => {
    // ２重送信防止
    if (contractRenewStatus === 'submitting') {
      return;
    }
    setIsConfirmOpen(false);
    const answeredValues: ContactObject_ContractRenewal = {};
    // 回答した項目のみ送信する（質問を戻った場合など不要な回答が残っている可能性があるため）
    for (const field of displayFields) {
      answeredValues[field] = values[field];
    }
    // 回答送信
    dispatch(
      answerContractRenewal({
        userId: userId(),
        objectId: contractRenewal.hs_object_id,
        values: answeredValues,
      }),
    );
    // メッセージ送信
    const text = ['【LINE契約更新：回答完了】'];
    if (formState.line_contract_renew_preference) {
      text.push(`次回の契約更新：${formState.line_contract_renew_preference}`);
    }
    if (formState.line_contaract_renew_procedure) {
      text.push(`今後の進め方：${formState.line_contaract_renew_procedure}`);
    }
    if (formState.line_contaract_renew_problem) {
      text.push(`理由：${formState.line_contaract_renew_problem}`);
    }
    if (formState.line_contaract_contact_preference) {
      text.push(`一次連絡手段：${formState.line_contaract_contact_preference}`);
    }
    if (formState.line_line_contaract_contact_time_preference) {
      text.push(
        `希望の連絡時間帯：${formState.line_line_contaract_contact_time_preference}`,
      );
    }
    liff.sendMessages([{ type: 'text', text: text.join(`\n`) }]);
  };

  /**
   * 表示情報の項目
   */
  const InfoItem: React.VFC<{ label: string; value?: string }> = (param) => {
    return (
      <div>
        <span>{param.label}：</span>
        <span>{param.value}</span>
      </div>
    );
  };

  type QuestionRadioSectionParam = {
    title: string;
    field: keyof ContactObject_ContractRenewal;
    options: { value: string; label?: string; submit?: boolean }[];
    hideDivider?: boolean;
    onChange?: (value: string) => void;
  };
  /**
   * ラジオボタンの質問
   */
  const QuestionRadioSection: React.VFC<QuestionRadioSectionParam> = (
    param,
  ) => {
    const scrollRef = React.useRef<HTMLDivElement>(null);

    React.useLayoutEffect(() => {
      scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    React.useEffect(() => {
      // 回答フィールドに追加
      displayFields.add(param.field);

      return () => {
        // 非表示になったら回答フィールドから削除
        displayFields.delete(param.field);
      };
    }, []);

    return (
      <>
        {!param.hideDivider && <Divider />}
        <FormControl ref={scrollRef}>
          <Box my={2}>
            <FormLabel
              id={param.field}
              style={{ color: '#0f1941', fontSize: '0.9rem' }}
            >
              Q. {param.title}
            </FormLabel>
          </Box>
          <RadioGroup
            aria-labelledby={param.field}
            name={param.field}
            value={formState[param.field]}
            onChange={(event) => {
              const values = { [param.field]: event.target.value };
              handleChange(values);
              if (param.onChange) {
                param.onChange(event.target.value);
              }
              // 送信する回答
              setIsCompleted(
                param.options.some(
                  (option) =>
                    option.submit && option.value === event.target.value,
                ),
              );
            }}
          >
            {param.options.map((option, index) => (
              <FormControlLabel
                key={`${param.field}_option_${index}`}
                value={option.value}
                control={<Radio />}
                disabled={contractRenewStatus !== 'idle'}
                label={
                  <span style={{ fontSize: '0.9rem' }}>
                    {option.label || option.value}
                  </span>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    );
  };

  /**
   * 閉じるボタン
   */
  const CloseButton: React.VFC = () => {
    return (
      <Button
        color="inherit"
        aria-label="next key"
        sx={{
          marginTop: 1,
          width: '100%',
        }}
        onClick={() => {
          liff.closeWindow();
        }}
      >
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>閉じる</span>
      </Button>
    );
  };

  return (
    <Box pb={8}>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#2175d9',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            契約更新確認
          </Typography>
        </Toolbar>
      </AppBar>
      {contractRenewal ? (
        <Stack
          m={2}
          padding={2}
          spacing={4}
          border={'1px solid rgba(215,215,215,1)'}
        >
          <Stack spacing={4}>
            <Box fontSize={'0.9rem'}>
              いつもご就業ありがとうございます。次回契約更新に関するご確認にてご連絡です。
              <br />
              今後の意向を早めに確認し、その意向に合わせてフォローや契約交渉をしていく目的でまずはLINEで意思のご確認をしております。
              <br />
              契約更新のご希望有無、営業担当との個別相談希望等を、アンケート形式でご回答ください。
            </Box>
            <Stack fontSize={'0.9rem'}>
              <InfoItem
                label={'スタッフコード'}
                value={contractRenewal.staffcobe}
              />
              <InfoItem
                label={'姓名'}
                value={`${contractRenewal.lastname || ''} ${
                  contractRenewal.firstname || ''
                }`}
              />
              <InfoItem
                label={'契約開始日'}
                value={
                  contractRenewal.f21_line_
                    ? moment(contractRenewal.f21_line_).format('YYYY年M月D日')
                    : ''
                }
              />
              <InfoItem
                label={'契約終了日'}
                value={
                  contractRenewal.f21_line_end
                    ? moment(contractRenewal.f21_line_end).format(
                        'YYYY年M月D日',
                      )
                    : ''
                }
              />
              <InfoItem
                label={'就業先企業名'}
                value={contractRenewal.line_ewclient_1}
              />
              <InfoItem
                label={'就業先本支店名'}
                value={contractRenewal.line_f21_}
              />
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Divider />
            <Stack
              direction={'row'}
              alignItems={'center'}
              color={'#0f1941'}
              fontSize={'1em'}
              spacing={'4px'}
            >
              <NoteAltOutlinedIcon style={{ fontSize: '1.4em' }} />
              <span>契約更新に関するアンケート</span>
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <QuestionRadioSection
              title="次回の契約更新に関してご回答ください"
              field="line_contract_renew_preference"
              options={[
                { value: '希望する' },
                { value: '希望しない' },
                { value: '未定・検討中' },
              ]}
              hideDivider={true}
            ></QuestionRadioSection>
            {formState.line_contract_renew_preference === '希望する' && (
              <QuestionRadioSection
                title="今後の進め方についてご回答ください"
                field="line_contaract_renew_procedure"
                options={[
                  {
                    value:
                      '特に就業状況に問題はないため、派遣先との確認を進めて欲しい',
                    submit: true,
                  },
                  { value: '契約更新は希望するが、別途相談事項あり' },
                ]}
              ></QuestionRadioSection>
            )}
            {formState.line_contract_renew_preference === '希望しない' && (
              <>
                <QuestionRadioSection
                  title="理由について簡単に教えてください"
                  field="line_contaract_renew_problem"
                  options={[
                    { value: '業務内容や就業環境等に不安があるため' },
                    { value: '自身の事情により' },
                    { value: 'その他の理由のため' },
                  ]}
                ></QuestionRadioSection>
              </>
            )}
            {formState.line_contract_renew_preference === '未定・検討中' ||
            (formState.line_contract_renew_preference === '希望しない' &&
              formState.line_contaract_renew_problem) ||
            (formState.line_contract_renew_preference === '希望する' &&
              formState.line_contaract_renew_procedure ===
                '契約更新は希望するが、別途相談事項あり') ? (
              <>
                <QuestionRadioSection
                  title="ご連絡の際に営業担当からの一次連絡手段はどちらがよいですか？"
                  field="line_contaract_contact_preference"
                  options={[
                    {
                      value: 'LINEでの連絡希望',
                      label: 'LINEでの連絡を希望',
                      submit: true,
                    },
                    { value: '電話での連絡希望', label: '電話での連絡を希望' },
                  ]}
                ></QuestionRadioSection>
              </>
            ) : (
              <></>
            )}
            {formState.line_contaract_contact_preference ===
              '電話での連絡希望' && (
              <>
                <QuestionRadioSection
                  title="希望の連絡時間帯はいつがよいですか？"
                  field="line_line_contaract_contact_time_preference"
                  options={[
                    { value: '午前', submit: true },
                    { value: '昼休み', submit: true },
                    { value: '午後', submit: true },
                    { value: '夕方', submit: true },
                  ]}
                ></QuestionRadioSection>
              </>
            )}

            {contractRenewStatus === 'submitting' && (
              <Box py={8} textAlign={'center'}>
                <CircularProgress />
              </Box>
            )}

            {contractRenewStatus === 'finished' &&
              (formState.line_contaract_renew_procedure ===
              '特に就業状況に問題はないため、派遣先との確認を進めて欲しい' ? (
                <Stack pt={4} spacing={4}>
                  <Box fontSize={'14px'}>
                    <p>ご回答いただきありがとうございました。</p>
                    <p>別途、確認を進めまして営業担当よりご連絡致します。</p>
                    <p>引き続きどうぞよろしくお願い致します。</p>
                  </Box>
                  <CloseButton />
                </Stack>
              ) : (
                <Stack pt={4} spacing={4}>
                  <Box fontSize={'14px'}>
                    <p>ご回答いただきありがとうございました。</p>
                    <p>
                      近日中に営業担当より連絡させていただき、ご状況や今後の進め方のご希望等について具体的にお聞かせ願えればと思います。
                    </p>
                    <p>
                      なお、このアンケートの回答内容によって契約更新が確定されるわけではありません。
                      <br />
                      契約更新希望の場合については就業先の企業とも交渉を進めたうえで、あらためて契約更新の有無を営業担当よりご連絡させていただきます。
                    </p>
                  </Box>
                  <CloseButton />
                </Stack>
              ))}

            {contractRenewStatus === 'failed' && (
              <Stack py={8} spacing={4}>
                <div>
                  エラーのため回答に失敗しました。
                  <br />
                </div>
                <CloseButton />
              </Stack>
            )}
          </Stack>

          {isCompleted && contractRenewStatus === 'idle' && (
            <AppBar
              position="fixed"
              color="primary"
              sx={{ top: 'auto', bottom: 0, backgroundColor: '#1d6ad4' }}
            >
              <Toolbar>
                <Button
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ width: '100%' }}
                  onClick={() => setIsConfirmOpen(true)}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    回答する
                  </span>
                </Button>
              </Toolbar>
            </AppBar>
          )}
        </Stack>
      ) : (
        <Stack
          m={2}
          padding={2}
          spacing={4}
          border={'1px solid rgba(215,215,215,1)'}
        >
          <p>回答可能な契約更新がありません。</p>
          <CloseButton />
        </Stack>
      )}

      <Dialog
        open={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            この内容で回答しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmOpen(false)} autoFocus>
            いいえ
          </Button>
          <Button onClick={() => handleSubmit(formState)}>はい</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContractRenewalForm;
