import * as React from 'react';
import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from '@mui/material';
import { useLocation, useMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import queryString from 'query-string';

import BusinessIcon from '@mui/icons-material/Business';
import PlaceIcon from '@mui/icons-material/Place';
import TrainIcon from '@mui/icons-material/Train';
import YenIcon from '@mui/icons-material/CurrencyYen';

import {
  isLoaded as isLoadedProfile,
  removePin,
  retrieveProfile,
  savePin,
  selectProfile,
} from '../features/profile/profileSlice';
import {
  isLoaded as isLoadedJob,
  logLastJobClickDate,
  retrieveJob,
  selectJobs,
} from '../features/job/jobSlice';

import { userId } from '../utils/liff';
import liff from '@line/liff';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';

const PROFILE_LIFF_ID = process.env.REACT_APP_PROFILE_LIFF_ID || '';

export default function Detail() {
  const match = useMatch('/detail/:number');
  let number: any = match?.params?.number;
  // console.log(number);
  if (!number) {
    try {
      const search = useLocation().search;
      const query = queryString.parse(search);
      // console.log(query);
      number = query['number'] || '';
    } catch (e) {
      console.error(e);
    }
  }

  const dispatch = useAppDispatch();

  const profile: any = useAppSelector(selectProfile);
  const profileLoaded = useAppSelector(isLoadedProfile);

  const jobs = useAppSelector(selectJobs);
  const jobLoaded = useAppSelector(isLoadedJob);

  let job: any = null;
  if (jobs.length > 0) {
    job = jobs.find((j) => j.number === number) || {};
  }

  useEffect(() => {
    document.title = `仕事No. ${number}`;

    if (!profileLoaded) {
      dispatch(
        retrieveProfile({
          userId: userId(),
        }),
      );
    } else if (!jobLoaded) {
      dispatch(
        retrieveJob({
          number,
        }),
      );

      dispatch(
        logLastJobClickDate({
          userId: userId(),
        }),
      );
    }
  }, [profileLoaded, jobLoaded]);

  let pinned = false;
  if (profile && profile.pins) {
    pinned = profile.pins.includes(number);
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSavePin = () => {
    setOpen(true);

    dispatch(
      savePin({
        userId: userId(),
        number,
      }),
    );

    const text = `[お仕事ピン留め]
お仕事No.${number}「${job.title}」`;

    liff
      .sendMessages([
        {
          type: 'text',
          text,
        },
      ])
      .then(() => {
        console.log('message sent');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleRemovePin = () => {
    setOpen2(true);

    dispatch(
      removePin({
        userId: userId(),
        number,
      }),
    );
  };

  const [open3, setOpen3] = React.useState(false);
  const handleClose3 = () => {
    setOpen3(false);
  };

  const openConfirm = () => {
    setOpen3(true);
  };

  const [open4, setOpen4] = React.useState(false);
  const handleClose4 = () => {
    setOpen4(false);

    liff.closeWindow();
  };

  const handleApplyJob = () => {
    setOpen3(false);
    setOpen6(false);
    setOpen4(true);

    const text = `[お仕事応募]
お仕事No：${number}
${job.title}

連絡手段：${formState.values.contactPreference}
第１希望：${formState.values.preferenceHour1}
第２希望：${formState.values.preferenceHour2}
`;

    liff
      .sendMessages([
        {
          type: 'text',
          text,
        },
      ])
      .then(() => {
        console.log('message sent');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [open5, setOpen5] = React.useState(!userId());
  const handleClose5 = () => {
    setOpen5(false);
    liff.closeWindow();
  };

  // RS-25 LINE応募時の連絡手段を追加する
  const [open6, setOpen6] = React.useState(!userId());
  const openContactPreference = () => {
    setOpen6(true);
    setOpen3(false);
  };

  const handleClose6 = () => {
    setOpen6(false);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      contactPreference: '',
      preferenceHour1: '',
      preferenceHour2: '',
    },
    errors: {},
    touched: {},
  });

  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  scrollTo(0, 0);

  // 既に応募済みの求人かチェック
  const applied = (profile ? profile.applications || [] : []).some(
    (a: string) => a === number,
  );

  return !jobLoaded ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ flexGrow: 1 }}>
      {jobs.length === 0 || job === null ? (
        <span>
          <Grid
            container
            style={{
              padding: 22,
              backgroundColor: '#ffffff',
            }}
          >
            <Grid item xs={12}>
              お探しの求人情報が見つかりません。
              <br />
              掲載期間が終了した可能性があります。
              <br />
              <br />
              <IconButton
                edge="start"
                color="inherit"
                aria-label="other"
                onClick={() => {
                  liff.openWindow({
                    url: `https://liff.line.me/${PROFILE_LIFF_ID}`,
                  });
                }}
              >
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ flexGrow: 1, marginLeft: 1 }}
                >
                  {'検索条件の変更はこちら '}
                </Typography>
                <SavedSearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </span>
      ) : (
        <span>
          <Grid
            container
            style={{
              background: '#6ba5b4',
              padding: '1em 1em .5em .2em',
              color: '#ffffff',
            }}
          >
            <Grid item xs={3}>
              <img src={job.image} alt="Job image" height={66} />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6" gutterBottom component="div">
                {job.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              padding: 22,
              backgroundColor: '#ffffff',
            }}
          >
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={1}
                style={{
                  padding: '0 0 1em 0',
                }}
              >
                {job.conditions.map((condition: string, index: number) => {
                  return <Chip key={index} label={condition} />;
                })}
              </Stack>
              <div style={{ whiteSpace: 'pre-line' }}>
                {job.formattedDescription}
              </div>
              <br />
              <Divider variant="fullWidth" />
              <br />
            </Grid>
            <Grid item xs={12}>
              <React.Fragment>
                <div>
                  <BusinessIcon style={{ fontSize: 12 }} />
                  {job.industryType}
                </div>
                <div>
                  <PlaceIcon style={{ fontSize: 12 }} />
                  {job.locations}
                </div>
                <div>
                  <TrainIcon style={{ fontSize: 12 }} />
                  {job.station}
                </div>
                <div>
                  <YenIcon style={{ fontSize: 12 }} />
                  {job.salary}
                </div>
              </React.Fragment>
              <br />
              <Divider variant="fullWidth" />
              <br />
            </Grid>
            <Grid item xs={12}>
              <React.Fragment>
                <div>※詳細はお仕事のご紹介時にお伝えします</div>
                <div>{`求人担当: ${job.branch}`}</div>
                <div>{`TEL: ${job.telephone}`}</div>
              </React.Fragment>
              <div style={{ height: '66px' }} />
            </Grid>
          </Grid>
          <AppBar
            position="fixed"
            color="primary"
            sx={{
              top: 'auto',
              bottom: -2,
              backgroundColor: '#1d6ad4',
            }}
          >
            <Grid
              container
              style={{
                height: 55,
              }}
            >
              <Grid
                item
                xs={5}
                style={{
                  backgroundColor: pinned ? '#e74536' : '#ffac13',
                  textAlign: 'center',
                }}
              >
                {pinned ? (
                  <Button
                    color="inherit"
                    aria-label="remove pin"
                    sx={{
                      backgroundColor: '#e74536',
                      marginTop: 1,
                    }}
                    onClick={handleRemovePin}
                  >
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      ピン留めを外す
                    </span>
                  </Button>
                ) : (
                  <Button
                    color="inherit"
                    aria-label="push pin"
                    sx={{
                      backgroundColor: '#ffac13',
                      marginTop: 1,
                    }}
                    onClick={handleSavePin}
                  >
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      ピン留めする
                    </span>
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  textAlign: 'center',
                }}
              >
                <Button
                  color="inherit"
                  aria-label="open drawer"
                  onClick={openConfirm}
                  sx={{
                    marginTop: 1,
                  }}
                  disabled={applied}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {applied ? '応募済み' : 'この求人に応募する'}
                  </span>
                </Button>
              </Grid>
            </Grid>
            {/*</Toolbar>*/}
          </AppBar>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                お仕事をピン留めしました。
                <br />
                求人の掲載や募集が終了した際はピン留めリストから削除されます。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open2}
            onClose={handleClose2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                お仕事のピン留めを解除しました。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose2} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open3}
            onClose={handleClose3}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`お仕事No.${job.number}「${job.title}」に応募しますか？`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose3} autoFocus>
                いいえ
              </Button>
              <Button onClick={openContactPreference}>はい</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open6}
            onClose={handleClose6}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{
                color: '#0f1941',
              }}
            >
              {'連絡手段について'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  color: '#0f1941',
                }}
              >
                <div>
                  <Typography variant="body1" component="div">
                    ご選考を進めさせていただく事になった場合、当社担当よりご連絡させて頂く際の、ご希望のご連絡方法を選択してください。
                  </Typography>
                  <FormControl>
                    <div
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <RadioGroup
                        name="contactPreference"
                        value={formState.values.contactPreference}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(
                            event,
                            'contactPreference',
                            event.target.value,
                          );
                        }}
                      >
                        <FormControlLabel
                          value="LINE"
                          control={<Radio />}
                          label="LINE"
                        />
                        <FormControlLabel
                          value="電話"
                          control={<Radio />}
                          label="電話"
                        />
                      </RadioGroup>
                    </div>
                  </FormControl>
                </div>
                <div
                  style={{
                    display:
                      formState.values.contactPreference === '電話'
                        ? ''
                        : 'none',
                    marginTop: '10px',
                  }}
                >
                  <Typography variant="body1" component="div">
                    【電話を選択された方】
                  </Typography>
                  <Typography variant="body1" component="div">
                    ご希望の時間帯を選択してください。
                  </Typography>
                  <FormControl
                    style={{
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        marginTop: '10px',
                      }}
                    ></div>
                  </FormControl>
                </div>
                <div
                  style={{
                    display:
                      formState.values.contactPreference === '電話'
                        ? ''
                        : 'none',
                  }}
                >
                  <InputLabel
                    style={{
                      fontSize: '.9em',
                      color: '#0f1941',
                    }}
                  >
                    {'第１希望'}
                  </InputLabel>
                  <FormControl sx={{ width: '80%' }}>
                    <Select
                      variant="standard"
                      value={formState.values.preferenceHour1}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'preferenceHour1',
                          event.target.value,
                        );
                      }}
                    >
                      <MenuItem value={'9-12時'}>9-12時</MenuItem>
                      <MenuItem value={'2-13時'}>12-13時</MenuItem>
                      <MenuItem value={'13-18時'}>13-18時</MenuItem>
                      <MenuItem value={'18時以降'}>18時以降</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    display:
                      formState.values.contactPreference === '電話'
                        ? ''
                        : 'none',
                    marginTop: '20px',
                  }}
                >
                  <InputLabel
                    style={{
                      fontSize: '.9em',
                      color: '#0f1941',
                    }}
                  >
                    {'第２希望'}
                  </InputLabel>
                  <FormControl sx={{ width: '80%' }}>
                    <Select
                      variant="standard"
                      value={formState.values.preferenceHour2}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'preferenceHour2',
                          event.target.value,
                        );
                      }}
                    >
                      <MenuItem value={'9-12時'}>9-12時</MenuItem>
                      <MenuItem value={'2-13時'}>12-13時</MenuItem>
                      <MenuItem value={'13-18時'}>13-18時</MenuItem>
                      <MenuItem value={'18時以降'}>18時以降</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose6} autoFocus>
                いいえ
              </Button>
              <Button
                disabled={
                  !formState.values.contactPreference ||
                  (formState.values.contactPreference === '電話' &&
                    !formState.values.preferenceHour1)
                }
                onClick={handleApplyJob}
              >
                はい
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open4}
            onClose={handleClose4}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                お仕事に応募しました。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose4} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open5}
            onClose={handleClose5}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                スマートフォン版LINEでご利用になれます。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose5} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </span>
      )}
    </Box>
  );
}
