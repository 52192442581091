import * as React from 'react';
import { useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import liff from '@line/liff';
import { useAppDispatch } from '../app/hooks';
import { modifyAdvancePayEntry } from '../features/advancePay/advancePayEntrySlice';
import { userId } from '../utils/liff';

// @formatter:off

class AdvancePayEntryConfirmProps {
  profile: any;
  branch: string | undefined;
  entry: any;
  edit: boolean | undefined;
  created: boolean | undefined;
}

const AdvancePayEntryConfirm: React.FC<AdvancePayEntryConfirmProps> = ({
  created,
  profile,
  branch,
  entry,
  edit,
}) => {
  //interface formName {
  //  userName: string;
  //  firstname: string;
  //  staffCode: string;
  //  advancePayType: string;
  //  advancePayBranch: string;
  //}

  console.log('confirm');
  //値の初期値設定
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      ready: 'no',
      userBranch: entry.properties.maekyu_contract_branch, //支店
      appDate: entry.properties.maekyu_contract_appdate, //申込日
      staffCode: entry.properties.maekyucontract_staffcode, //スタッフコード
      userName: entry.properties.maekyucontract_name, //ユーザー名
      appAgree: entry.properties.maekyu_app_agree, //項目に同意
      contractAgree: entry.properties.maekyu_contract_agree, //契約書に同意
      bankAccountAgree: entry.properties.maekyu_account_agree, //前給給与の振り込み先口座確認
    },
    errors: {},
    touched: {},
  });

  console.log(entry);
  //console.log('entry_id' + entry.id);

  //後で切り替える
  const isNameError =
    entry.properties.hs_pipeline_stage === '185884851' ? true : false;
  const isStaffCodeError =
    entry.properties.hs_pipeline_stage === '185884850' ? true : false;
  //const isNameError =
  //  entry.properties.hs_pipeline_stage === '171059288' ? true : false;
  //const isStaffCodeError =
  //  entry.properties.hs_pipeline_stage === '171059288' ? true : false;

  //入力フォームの値をセット
  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  //ページ管理設定
  const steps = ['1', '2'];
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  //データ送信
  const dispatch = useAppDispatch();
  const [creating, setCreating] = React.useState(false);
  const submit = () => {
    setopenConfirmPost(false);
    setCreating(true);
    dispatch(
      modifyAdvancePayEntry({
        userId: userId(),
        values: formState.values,
        objectId: edit ? entry.id : null,
      }),
    );
  };

  if (created && activeStep !== 2) {
    //if (created) {
    setCreating(false);
    setActiveStep(2);
  }

  //ハンドル操作(ダイアログオープン/クローズ)
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };

  const [openConfirmPost, setopenConfirmPost] = React.useState(false);
  const handleCloseConfirmPost = () => {
    setopenConfirmPost(false);
  };

  //エラー設定
  const errorMessage: string[] = [];
  const [viewMessage, setViewMessage] = React.useState<Element | object>();

  //ハンドル操作(次ページへの遷移)
  const handleNext = () => {
    const specialAdd = 0;

    //エラーチェック
    if (activeStep === 1) {
      if (!formState.values.staffCode) {
        errorMessage.push('スタッフコードを入力してください。');
      } else if (String(formState.values.staffCode).length !== 8) {
        errorMessage.push('スタッフコードは8桁で入力してください。');
      }
      if (!formState.values.userName) {
        errorMessage.push('名前を入力してください。');
      }
    }
    const listMessage = errorMessage.map((message) => (
      <span>
        {message} <br />
      </span>
    ));
    setViewMessage(listMessage);

    if (errorMessage.length > 0) {
      setOpen2(true);
      return;
    } else {
      setopenConfirmPost(true);
      return;
    }

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1 + specialAdd;
    setActiveStep(newActiveStep);
    window.scrollTo({
      top: 0,
    });
  };

  //ハンドル操作(前ページへの遷移)
  const handleBack = () => {
    const specialAdd = 0;

    setActiveStep((prevActiveStep) => prevActiveStep - 1 - specialAdd);
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#0F1941',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            {activeStep === 2 ? 'おつかれさまでした' : '前給サービス申し込み'}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ m: 2 }}>
        {activeStep === 0 ? (
          <span>
            <div
              style={{
                padding: '1em',
                // marginTop: '1em',
              }}
            >
              <div
                style={{
                  color: '#2175D9',
                  fontWeight: 'bold',
                  marginBottom: '2em',
                  textAlign: 'center',
                }}
              >
                申込内容の確認ページ
              </div>
              {isNameError || isStaffCodeError ? (
                <div
                  style={{
                    color: '#E74536',
                  }}
                >
                  ※スタッフコード・申込者氏名に誤りがあります。編集ボタンをクリックして修正してください。
                </div>
              ) : (
                ''
              )}
              <div
                style={{
                  margin: '0px 0px 32px 0',
                }}
              >
                <div
                  style={{
                    margin: '16px 0px',
                    fontWeight: '600',
                  }}
                >
                  申込書・契約書
                </div>
                <div>
                  <div
                    style={{
                      margin: '16px 0px',
                      padding: '4px 0px',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    支払給与の内渡し（前給サービス）申込書 ：
                    <span>{entry.properties.maekyu_app_agree}</span>
                  </div>
                  <div
                    style={{
                      margin: '16px 0px',
                      padding: '4px 0px',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    前給契約書（支払給与の内渡契約書）：
                    <span>{entry.properties.maekyu_contract_agree}</span>
                  </div>
                  <div
                    style={{
                      margin: '16px 0px',
                      padding: '4px 0px',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    口座に関すること：
                    <span>{entry.properties.maekyu_account_agree}</span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  margin: '0px 0px 32px 0px',
                }}
              >
                <div
                  style={{
                    margin: '16px 0px',
                    fontWeight: '600',
                  }}
                >
                  申込者情報
                </div>
                <div>
                  <div
                    style={{
                      margin: '16px 0px',
                      padding: '4px 0px',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    申込日：
                    <span>
                      {entry.properties.maekyu_contract_appdate.replace(
                        '/-/g',
                        '/',
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      margin: '16px 0px',
                      padding: '4px 0px',
                      borderBottom: '1px solid #ccc',
                      color: isStaffCodeError === true ? '#E74536' : '',
                    }}
                  >
                    スタッフコード：
                    <span>{entry.properties.maekyucontract_staffcode}</span>
                  </div>
                  <div
                    style={{
                      margin: '16px 0px',
                      padding: '4px 0px',
                      borderBottom: '1px solid #ccc',
                      color: isNameError === true ? '#E74536' : '',
                    }}
                  >
                    申込者氏名：
                    <span>{entry.properties.maekyucontract_name}</span>
                  </div>
                  <div
                    style={{
                      margin: '16px 0px',
                      padding: '4px 0px',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    担当支店：
                    {entry.properties.maekyu_contract_branch}
                  </div>
                </div>
              </div>
            </div>
            <Box sx={{ mt: 9 }} />
          </span>
        ) : (
          ''
        )}
        {activeStep === 1 ? (
          <div>
            <FormControl sx={{ ml: 0, mt: 1, mr: 2, mb: 2, width: '80%' }}>
              <div>
                <TextField
                  id="staff-code"
                  variant="standard"
                  label="スタッフコード"
                  type="tel"
                  placeholder="00000000"
                  value={formState.values.staffCode}
                  error={formState.values.staffCode === ''}
                  inputProps={{ maxLength: '8' }}
                  onChange={(event) => {
                    //console.log(event.target.value);
                    handleChange(event, 'staffCode', event.target.value);
                  }}
                  sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '92%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div>
                <TextField
                  id="standard-basic"
                  label="名前"
                  variant="standard"
                  type="text"
                  value={formState.values.userName}
                  error={formState.values.userName === ''}
                  onChange={(event) => {
                    handleChange(event, 'userName', event.target.value);
                  }}
                  sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '92%' }}
                  placeholder="山田太郎"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </FormControl>
          </div>
        ) : (
          ''
        )}
        {activeStep === 2 ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                padding: '1em',
                // marginTop: '1em',
              }}
            >
              おつかれさまでした。
              <br />
              <br />
              こちらのページでお申し込みは完了となります。
              <br />
              <br />
              修正内容の確認後、LINEより完了のメッセージをお送りいたしますので今しばらくお待ちください。
              <br />
              <br />
              尚、本件に関するお問合せについては担当支店までご連絡下さい。
              <br />
            </div>
            <div
              style={{
                border: '1px dotted #2175D9',
                padding: '16px 16px 16px 16px',
                margin: '32px 0px 32px 0px',
                color: '#2175D9',
              }}
            >
              <p>●入社手続き時に給与口座申請がまだの方</p>
              <p>●入社後にまだお給与のお受け取りをされていない方</p>
            </div>
            <div>
              前給振込までにお口座登録がない場合、お振込みに間に合わない場合がございます。
              こちらの申込が完了後、
              <span
                style={{
                  textDecoration: 'underline',
                }}
              >
                LINEトーク画面上
              </span>
              に給与口座のご登録ページをご案内させていただきます。必ず口座登録を行ってください。
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )}
        {activeStep < 2 ? (
          <Grid
            item
            xs={7}
            style={{
              textAlign: 'center',
            }}
          >
            <AppBar
              position="fixed"
              color="primary"
              sx={{
                top: 'auto',
                bottom: -2,
                backgroundColor: '#2175D9',
              }}
            >
              <Grid
                container
                style={{
                  height: 55,
                }}
              >
                {activeStep === 0 ? (
                  <React.Fragment>
                    <Grid
                      item
                      xs={6}
                      sx={{ width: '100%' }}
                      style={{
                        backgroundColor: '#d9d9d9',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="next key"
                        sx={{
                          marginTop: 1,
                          flexGrow: 1,
                          width: '100%',
                          color: '#1d6ad4',
                        }}
                        onClick={liff.closeWindow}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                          }}
                        >
                          閉じる
                        </span>
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="back key"
                        sx={{
                          marginTop: 1,
                          width: '100%',
                          flexGrow: 1,
                        }}
                        onClick={() => {
                          isNameError || isStaffCodeError
                            ? setActiveStep(1)
                            : setOpen(true);
                        }}
                      >
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          編集する
                        </span>
                      </Button>
                    </Grid>
                  </React.Fragment>
                ) : (
                  ''
                )}
                {activeStep === 1 ? (
                  <React.Fragment>
                    <Grid
                      item
                      xs={6}
                      sx={{ width: '100%' }}
                      style={{
                        backgroundColor: '#d9d9d9',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="next key"
                        sx={{
                          marginTop: 1,
                          flexGrow: 1,
                          width: '100%',
                          color: '#1d6ad4',
                        }}
                        onClick={handleBack}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                          }}
                        >
                          戻る
                        </span>
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="back key"
                        sx={{
                          marginTop: 1,
                          width: '100%',
                          flexGrow: 1,
                        }}
                        onClick={handleNext}
                      >
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          再申請する
                        </span>
                      </Button>
                    </Grid>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </Grid>
              {/*</Toolbar>*/}
            </AppBar>
          </Grid>
        ) : (
          ''
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              申請が完了しているため編集できません。
              <br />
              変更をご希望の場合は、担当コンサルタントへご連絡ください。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {viewMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openConfirmPost}
          onClose={handleCloseConfirmPost}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'登録内容に間違いなければ登録してください。'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div
                style={{
                  margin: '16px 0px',
                  padding: '4px 0px',
                  borderBottom: '1px solid #ccc',
                }}
              >
                スタッフコード：
                <span>{formState.values.staffCode}</span>
              </div>
              <div
                style={{
                  margin: '16px 0px',
                  padding: '4px 0px',
                  borderBottom: '1px solid #ccc',
                }}
              >
                申込者氏名：
                <span>{formState.values.userName}</span>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmPost} autoFocus>
              戻る
            </Button>
            <Button onClick={submit} autoFocus>
              登録
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </span>
  );
};

export default AdvancePayEntryConfirm;
