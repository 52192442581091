import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';
const API_KEY = process.env.REACT_APP_CONTACT_API_KEY || '';
const OBJECT_TYPE = process.env.REACT_APP_CONTRACT_RENEWAL_OBJECT_TYPE || '';
const PIPELINE_STAGE_ARCHIVE_ANSWERED =
  process.env.REACT_APP_CONTRACT_RENEWAL_PIPELINE_STAGE_ARCHIVE_ANSWERED || '';
const PIPELINE_STAGE_ARCHIVE_UNANSWERED =
  process.env.REACT_APP_CONTRACT_RENEWAL_PIPELINE_STAGE_ARCHIVE_UNANSWERED ||
  '';

export interface contractRenewalState {
  jobs: any[];
  status: 'idle' | 'loading' | 'submitting' | 'finished' | 'failed';
  contractRenewal: any;
  loaded: boolean;
  completed: boolean;
}

/**
 * 契約更新で更新するコンタクトのオブジェクト
 */
export interface ContactObject_ContractRenewal {
  /** 契約更新 - アンケート自動送付日（LINE） */
  line_contaract_renew_survey_sent_date?: string | null;
  /** 契約更新 - アンケート自動督促日（LINE） */
  line_contaract_renew_survey_remind_date?: string | null;
  /** 契約更新 - アンケート送付元LINEアカウント（LINE） */
  line_contaract_renew_survey_sender_account?: string | null;
  /** 契約更新 - 希望（LINE） */
  line_contract_renew_preference?: string | null;
  /** 契約更新 - 進め方の希望（LINE） */
  line_contaract_renew_procedure?: string | null;
  /** 契約更新 - 相談事項（LINE） */
  line_contaract_renew_problem?: string | null;
  /** 契約更新 - 連絡時間帯の希望（LINE） */
  line_line_contaract_contact_time_preference?: string | null;
  /** 契約更新 - 連絡方法の希望（LINE） */
  line_contaract_contact_preference?: string | null;
}

interface ContractRenewalParams {
  userId: string;
  objectId: string;
  values: ContactObject_ContractRenewal;
}

const initialState: contractRenewalState = {
  jobs: [],
  status: 'idle',
  contractRenewal: null,
  loaded: false,
  completed: false,
};

export const retrieveContractRenew = createAsyncThunk(
  'retrieveContractRenew',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/line/v1/custom-object/${params.userId}/${OBJECT_TYPE}?apikey=${API_KEY}`,
    );
    let records = response.data;
    records = records.filter(
      (record: any) =>
        ![
          PIPELINE_STAGE_ARCHIVE_ANSWERED,
          PIPELINE_STAGE_ARCHIVE_UNANSWERED,
        ].includes(record.properties.hs_pipeline_stage),
    );
    return records.length > 0 ? records[0].properties : null;
  },
);

export const answerContractRenewal = createAsyncThunk(
  'answerContractRenewal',
  async (params: ContractRenewalParams) => {
    try {
      // 前の回答を上書きするためクリアしてから設定
      const clearObj: ContactObject_ContractRenewal = {
        line_contract_renew_preference: '',
        line_contaract_renew_procedure: '',
        line_contaract_renew_problem: '',
        line_line_contaract_contact_time_preference: '',
        line_contaract_contact_preference: '',
      };
      const properties = {
        ...clearObj,
        ...params.values,
      };
      const response = await axios.patch(
        `${API_SERVER_URL}/line/v1/custom-object/${params.userId}?apikey=${API_KEY}`,
        {
          objectType: OBJECT_TYPE,
          objectId: params.objectId,
          associationType: 'LINE契約更新_to_contact',
          properties,
        },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const contractRenewSlice = createSlice({
  name: 'contractRenew',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveContractRenew.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveContractRenew.fulfilled, (state, action) => {
        state.status = 'idle';
        state.contractRenewal = action.payload;
        state.loaded = true;
      })
      .addCase(retrieveContractRenew.rejected, (state) => {
        state.status = 'failed';
        state.loaded = true;
      });

    builder
      .addCase(answerContractRenewal.pending, (state) => {
        state.status = 'submitting';
      })
      .addCase(answerContractRenewal.fulfilled, (state) => {
        state.status = 'finished';
        state.completed = true;
      })
      .addCase(answerContractRenewal.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectContractRenew = (state: RootState) =>
  state.contractRenew.contractRenewal;
export const selectContractRenewStatus = (state: RootState) =>
  state.contractRenew.status;
export const isLoaded = (state: RootState) => state.contractRenew.loaded;

export default contractRenewSlice.reducer;
