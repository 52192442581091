import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import jobReducer from '../features/job/jobSlice';
import profileReducer from '../features/profile/profileSlice';
import applicationReducer from '../features/application/applicationSlice';
import entryReducer from '../features/entry/entrySlice';
import advancePayReducer from '../features/advancePay/advancePaySlice';
import advancePayEntryReducer from '../features/advancePay/advancePayEntrySlice';
import insuranceReducer from '../features/socialInsurance/socialInsuranceSlice';
import contractRenewReducer from '../features/contractRenewal/contractRenewalSlice';
import periodicSurveyReducer from '../features/periodicSurvey/periodicSurveySlice';

export const store = configureStore({
  reducer: {
    job: jobReducer,
    profile: profileReducer,
    application: applicationReducer,
    entry: entryReducer,
    advancePay: advancePayReducer,
    advancePayEntry: advancePayEntryReducer,
    insurance: insuranceReducer,
    contractRenew: contractRenewReducer,
    periodicSurvey: periodicSurveyReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
