import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';
const API_KEY = process.env.REACT_APP_CONTACT_API_KEY || '';
const OBJECT_TYPE = process.env.REACT_APP_ENTRY_OBJECT_TYPE || '';
const PIPELINE = process.env.REACT_APP_TRAINING_PIPELINE || '';
const PIPELINE_STAGE = process.env.REACT_APP_TRAINING_PIPELINE_STAGE || '';

export interface StartupState {
  jobs: any[];
  status: 'idle' | 'loading' | 'failed';
  loaded: boolean;
}

const initialState: StartupState = {
  jobs: [],
  status: 'idle',
  loaded: false,
};

export const answerStartup = createAsyncThunk(
  'answerStartup',
  async (params: any) => {
    try {
      const data: any = {
        objectType: `${OBJECT_TYPE}`,
        associationType: '入職手続き_to_contact',
        // associationType: '入社手続き_to_contact',
        properties: {
          // パイプライン
          hs_pipeline: PIPELINE,
          hs_pipeline_stage: PIPELINE_STAGE,

          // 基本情報
          name: params.values.lastname + ' ' + params.values.firstname,
          lastname: params.values.lastname,
          firstname: params.values.firstname,
          staff_code: params.values.staffCode,

          // スタートアップ研修
          startup_check: params.values.training,
          startup_segment: params.values.segment,
          startup_date: params.values.checkDate,
          startup_company_name: params.values.company,
          startup_branch_name: params.values.branch,
          startup_manager_sales: params.values.manager,
          startup_employment_type: params.values.employmentType,
        },
      };

      const response = await axios.post(
        `${API_SERVER_URL}/line/v1/custom-object/${params.userId}?apikey=${API_KEY}`,
        data,
      );

      return response.data;
    } catch (e) {
      console.error(e);
      alert(
        `エラーのため登録に失敗しました。管理者に連絡してください。 LINE ID: ${params.userId} - ${e}`,
      );
      window.location.reload();
    }
  },
);

export const startupSlice = createSlice({
  name: 'startup',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(answerStartup.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(answerStartup.fulfilled, (state) => {
        state.status = 'idle';
        state.loaded = true;
      });
  },
});

export default startupSlice.reducer;
