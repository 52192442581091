import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import liff from '@line/liff';
import moment from 'moment';
import axios from 'axios';

class EntryFormProps {
  entry: any;
  branch: string | undefined;
  prev: string | undefined;
}

const EntryConfirm: React.FC<EntryFormProps> = ({ entry, branch, prev }) => {
  const [formState] = useState({
    isValid: false,
    values: {
      // 誓約書
      writtenOath: entry.properties.written_oath || '',
      emergencyLastname: entry.properties.emergency_lastname || '',
      emergencyFirstname: entry.properties.emergency_firstname || '',
      emergencyRelationship: entry.properties.emergency_relationship || '',
      emergencyTelNumber: entry.properties.emergency_tel_number || '',

      // 共通項目
      lastname: entry.properties.lastname || '',
      lastnameRuby: entry.properties.lastname_ruby || '',
      firstname: entry.properties.firstname || '',
      firstnameRuby: entry.properties.firstname_ruby || '',
      birthday: entry.properties.birthday || '',
      staffCode: entry.properties.staff_code || '',
      gender: entry.properties.gender || '',
      startDate: entry.properties.start_date || '',
      branch: entry.properties.branch || '',
      postCode1: entry.properties.post_code1 || '',
      prefecture1: entry.properties.prefecture1 || '',
      municipalities1: entry.properties.municipalities1 || '',
      townArea1: entry.properties.town_area1 || '',
      otherAddress1: entry.properties.other_address1 || '',
      registrationAddress: entry.properties.registration_address || '',
      postCode2: entry.properties.post_code2 || '',
      prefecture2: entry.properties.prefecture2 || '',
      municipalities2: entry.properties.municipalities2 || '',
      townArea2: entry.properties.town_area2 || '',
      otherAddress2: entry.properties.other_address2 || '',

      // マル扶
      lastname3: entry.properties.lastname3 || '',
      firstname3: entry.properties.firstname3 || '',
      relationship3: entry.properties.relationship3 || '',
      handicapped: entry.properties.handicapped || '',
      handicappedDetail: entry.properties.handicapped_detail || '',
      handicappedDegree: entry.properties.handicapped_degree || '',
      handicappedOther: entry.properties.handicappedother || '',
      deliveryDate: entry.properties.delivery_date || '',
      workingStudent: entry.properties.working_student || '',
      schoolLabel: entry.properties.school_label || '',
      schoolName: entry.properties.school_name || '',
      admissionDate: entry.properties.admission_date || '',
      household: entry.properties.household || '',
      marriage: entry.properties.marriage || '',
      dependentDeclaration: entry.properties.dependent_declaration || '',
      situationSelection: entry.properties.situation_selection || '',

      // マル扶 扶養者情報(パートナー)
      partnerRelationship: entry.properties.partner_relationship || '',
      partnerLastname: entry.properties.partner_lastname || '',
      partnerLastnameRuby: entry.properties.partner_lastname_ruby || '',
      partnerFirstname: entry.properties.partner_firstname || '',
      partnerFirstnameRuby: entry.properties.partner_firstname_ruby || '',
      partnerBirthday: entry.properties.partner_birthday || '',
      partnerGender: entry.properties.partner_gender || '',
      partnerSalary: entry.properties.partner_salary || '',
      partnerPension: entry.properties.partner_pension || '',
      partnerOtherIncome: entry.properties.partner_other_income || '',
      partnerLiving: entry.properties.partner_living || '',
      partnerPostCode: entry.properties.partner_post_code || '',
      partnerPrefecture: entry.properties.partner_prefecture || '',
      partnerMunicipalities: entry.properties.partner_municipalities || '',
      partnerTownArea: entry.properties.partner_town_area || '',
      partnerOtherAddress: entry.properties.partner_other_address || '',
      partnerHandicapped: entry.properties.partner_handicapped || '',
      partnerHandicappedDetail:
        entry.properties.partner_handicapped_detail || '',
      partnerHandicappedDegree:
        entry.properties.partner_handicapped_degree || '',
      partnerHandicappedOther: entry.properties.partnerhandicappedother || '',
      partnerDeliveryDate: entry.properties.partner_delivery_date || '',
      partnerBereavement: entry.properties.partner_bereavement || '',

      // マル扶 扶養者情報(扶養親族1)
      relativesLastname1: entry.properties.relatives_lastname1 || '',
      relativesLastnameRuby1: entry.properties.relatives_lastname_ruby1 || '',
      relativesFirstname1: entry.properties.relatives_firstname1 || '',
      relativesFirstnameRuby1: entry.properties.relatives_firstname_ruby1 || '',
      relativesBirthday1: entry.properties.relatives_birthday1 || '',
      relativesGender1: entry.properties.relatives_gender1 || '',
      relativesRelationship1: entry.properties.relatives_relationship1 || '',
      relativesSalary1: entry.properties.relatives_salary1 || '',
      relativesPension1: entry.properties.relatives_pension1 || '',
      relativesOtherIncome1: entry.properties.relatives_other_income1 || '',
      relativesLiving1: entry.properties.relatives_living1 || '',
      relativesPostCode1: entry.properties.relatives_post_code1 || '',
      relativesPrefecture1: entry.properties.relatives_prefecture1 || '',
      relativesMunicipalities1:
        entry.properties.relatives_municipalities1 || '',
      relativesTownArea1: entry.properties.relatives_town_area1 || '',
      relativesOtherAddress1: entry.properties.relatives_other_address1 || '',
      relativesHandicapped1: entry.properties.relatives_handicapped1 || '',
      relativesHandicappedDetail1:
        entry.properties.relatives_handicapped_detail1 || '',
      relativesHandicappedDegree1:
        entry.properties.relatives_handicapped_degree1 || '',
      relativesHandicappedOther1:
        entry.properties.relativeshandicappedother1 || '',
      relativesDeliveryDate1: entry.properties.relatives_delivery_date1 || '',
      relativesBereavement1: entry.properties.registration_bereavement1 || '',

      // マル扶 扶養者情報(扶養親族2)
      relativesLastname2: entry.properties.relatives_lastname2 || '',
      relativesLastnameRuby2: entry.properties.relatives_lastname_ruby2 || '',
      relativesFirstname2: entry.properties.relatives_firstname2 || '',
      relativesFirstnameRuby2: entry.properties.relatives_firstname_ruby2 || '',
      relativesBirthday2: entry.properties.relatives_birthday2 || '',
      relativesGender2: entry.properties.relatives_gender2 || '',
      relativesRelationship2: entry.properties.relatives_relationship2 || '',
      relativesSalary2: entry.properties.relatives_salary2 || '',
      relativesPension2: entry.properties.relatives_pension2 || '',
      relativesOtherIncome2: entry.properties.relatives_other_income2 || '',
      relativesLiving2: entry.properties.relatives_living2 || '',
      relativesPostCode2: entry.properties.relatives_post_code2 || '',
      relativesPrefecture2: entry.properties.relatives_prefecture2 || '',
      relativesMunicipalities2:
        entry.properties.relatives_municipalities2 || '',
      relativesTownArea2: entry.properties.relatives_town_area2 || '',
      relativesOtherAddress2: entry.properties.relatives_other_address2 || '',
      relativesHandicapped2: entry.properties.relatives_handicapped2 || '',
      relativesHandicappedDetail2:
        entry.properties.relatives_handicapped_detail2 || '',
      relativesHandicappedDegree2:
        entry.properties.relatives_handicapped_degree2 || '',
      relativesHandicappedOther2:
        entry.properties.relativeshandicappedother2 || '',
      relativesDeliveryDate2: entry.properties.relatives_delivery_date2 || '',
      relativesBereavement2: entry.properties.registration_bereavement2 || '',

      // マル扶 扶養者情報(扶養親族3)
      relativesLastname3: entry.properties.relatives_lastname3 || '',
      relativesLastnameRuby3: entry.properties.relatives_lastname_ruby3 || '',
      relativesFirstname3: entry.properties.relatives_firstname3 || '',
      relativesFirstnameRuby3: entry.properties.relatives_firstname_ruby3 || '',
      relativesBirthday3: entry.properties.relatives_birthday3 || '',
      relativesGender3: entry.properties.relatives_gender3 || '',
      relativesRelationship3: entry.properties.relatives_relationship3 || '',
      relativesSalary3: entry.properties.relatives_salary3 || '',
      relativesPension3: entry.properties.relatives_pension3 || '',
      relativesOtherIncome3: entry.properties.relatives_other_income3 || '',
      relativesLiving3: entry.properties.relatives_living3 || '',
      relativesPostCode3: entry.properties.relatives_post_code3 || '',
      relativesPrefecture3: entry.properties.relatives_prefecture3 || '',
      relativesMunicipalities3:
        entry.properties.relatives_municipalities3 || '',
      relativesTownArea3: entry.properties.relatives_town_area3 || '',
      relativesOtherAddress3: entry.properties.relatives_other_address3 || '',
      relativesHandicapped3: entry.properties.relatives_handicapped3 || '',
      relativesHandicappedDetail3:
        entry.properties.relatives_handicapped_detail3 || '',
      relativesHandicappedDegree3:
        entry.properties.relatives_handicapped_degree3 || '',
      relativesHandicappedOther3:
        entry.properties.relativeshandicappedother3 || '',
      relativesDeliveryDate3: entry.properties.relatives_delivery_date3 || '',
      relativesBereavement3: entry.properties.registration_bereavement3 || '',

      // マル扶 扶養者情報(扶養親族4)
      relativesLastname4: entry.properties.relatives_lastname4 || '',
      relativesLastnameRuby4: entry.properties.relatives_lastname_ruby4 || '',
      relativesFirstname4: entry.properties.relatives_firstname4 || '',
      relativesFirstnameRuby4: entry.properties.relatives_firstname_ruby4 || '',
      relativesBirthday4: entry.properties.relatives_birthday4 || '',
      relativesGender4: entry.properties.relatives_gender4 || '',
      relativesRelationship4: entry.properties.relatives_relationship4 || '',
      relativesSalary4: entry.properties.relatives_salary4 || '',
      relativesPension4: entry.properties.relatives_pension4 || '',
      relativesOtherIncome4: entry.properties.relatives_other_income4 || '',
      relativesLiving4: entry.properties.relatives_living4 || '',
      relativesPostCode4: entry.properties.relatives_post_code4 || '',
      relativesPrefecture4: entry.properties.relatives_prefecture4 || '',
      relativesMunicipalities4:
        entry.properties.relatives_municipalities4 || '',
      relativesTownArea4: entry.properties.relatives_town_area4 || '',
      relativesOtherAddress4: entry.properties.relatives_other_address4 || '',
      relativesHandicapped4: entry.properties.relatives_handicapped4 || '',
      relativesHandicappedDetail4:
        entry.properties.relatives_handicapped_detail4 || '',
      relativesHandicappedDegree4:
        entry.properties.relatives_handicapped_degree4 || '',
      relativesHandicappedOther4:
        entry.properties.relativeshandicappedother4 || '',
      relativesDeliveryDate4: entry.properties.relatives_delivery_date4 || '',
      relativesBereavement4: entry.properties.registration_bereavement4 || '',

      // マル扶 扶養者情報(扶養親族5)
      relativesLastname5: entry.properties.relatives_lastname5 || '',
      relativesLastnameRuby5: entry.properties.relatives_lastname_ruby5 || '',
      relativesFirstname5: entry.properties.relatives_firstname5 || '',
      relativesFirstnameRuby5: entry.properties.relatives_firstname_ruby5 || '',
      relativesBirthday5: entry.properties.relatives_birthday5 || '',
      relativesGender5: entry.properties.relatives_gender5 || '',
      relativesRelationship5: entry.properties.relatives_relationship5 || '',
      relativesSalary5: entry.properties.relatives_salary5 || '',
      relativesPension5: entry.properties.relatives_pension5 || '',
      relativesOtherIncome5: entry.properties.relatives_other_income5 || '',
      relativesLiving5: entry.properties.relatives_living5 || '',
      relativesPostCode5: entry.properties.relatives_post_code5 || '',
      relativesPrefecture5: entry.properties.relatives_prefecture5 || '',
      relativesMunicipalities5:
        entry.properties.relatives_municipalities5 || '',
      relativesTownArea5: entry.properties.relatives_town_area5 || '',
      relativesOtherAddress5: entry.properties.relatives_other_address5 || '',
      relativesHandicapped5: entry.properties.relatives_handicapped5 || '',
      relativesHandicappedDetail5:
        entry.properties.relatives_handicapped_detail5 || '',
      relativesHandicappedDegree5:
        entry.properties.relatives_handicapped_degree5 || '',
      relativesHandicappedOther5:
        entry.properties.relativeshandicappedother5 || '',
      relativesDeliveryDate5: entry.properties.relatives_delivery_date5 || '',
      relativesBereavement5: entry.properties.registration_bereavement5 || '',

      // マル扶 扶養者情報(扶養親族6)
      relativesLastname6: entry.properties.relatives_lastname6 || '',
      relativesLastnameRuby6: entry.properties.relatives_lastname_ruby6 || '',
      relativesFirstname6: entry.properties.relatives_firstname6 || '',
      relativesFirstnameRuby6: entry.properties.relatives_firstname_ruby6 || '',
      relativesBirthday6: entry.properties.relatives_birthday6 || '',
      relativesGender6: entry.properties.relatives_gender6 || '',
      relativesRelationship6: entry.properties.relatives_relationship6 || '',
      relativesSalary6: entry.properties.relatives_salary6 || '',
      relativesPension6: entry.properties.relatives_pension6 || '',
      relativesOtherIncome6: entry.properties.relatives_other_income6 || '',
      relativesLiving6: entry.properties.relatives_living6 || '',
      relativesPostCode6: entry.properties.relatives_post_code6 || '',
      relativesPrefecture6: entry.properties.relatives_prefecture6 || '',
      relativesMunicipalities6:
        entry.properties.relatives_municipalities6 || '',
      relativesTownArea6: entry.properties.relatives_town_area6 || '',
      relativesOtherAddress6: entry.properties.relatives_other_address6 || '',
      relativesHandicapped6: entry.properties.relatives_handicapped6 || '',
      relativesHandicappedDetail6:
        entry.properties.relatives_handicapped_detail6 || '',
      relativesHandicappedDegree6:
        entry.properties.relatives_handicapped_degree6 || '',
      relativesHandicappedOther6:
        entry.properties.relativeshandicappedother6 || '',
      relativesDeliveryDate6: entry.properties.relatives_delivery_date6 || '',
      relativesBereavement6: entry.properties.registration_bereavement6 || '',

      // マル扶 扶養者情報(扶養親族7)
      relativesLastname7: entry.properties.relatives_lastname7 || '',
      relativesLastnameRuby7: entry.properties.relatives_lastname_ruby7 || '',
      relativesFirstname7: entry.properties.relatives_firstname7 || '',
      relativesFirstnameRuby7: entry.properties.relatives_firstname_ruby7 || '',
      relativesBirthday7: entry.properties.relatives_birthday7 || '',
      relativesGender7: entry.properties.relatives_gender7 || '',
      relativesRelationship7: entry.properties.relatives_relationship7 || '',
      relativesSalary7: entry.properties.relatives_salary7 || '',
      relativesPension7: entry.properties.relatives_pension7 || '',
      relativesOtherIncome7: entry.properties.relatives_other_income7 || '',
      relativesLiving7: entry.properties.relatives_living7 || '',
      relativesPostCode7: entry.properties.relatives_post_code7 || '',
      relativesPrefecture7: entry.properties.relatives_prefecture7 || '',
      relativesMunicipalities7:
        entry.properties.relatives_municipalities7 || '',
      relativesTownArea7: entry.properties.relatives_town_area7 || '',
      relativesOtherAddress7: entry.properties.relatives_other_address7 || '',
      relativesHandicapped7: entry.properties.relatives_handicapped7 || '',
      relativesHandicappedDetail7:
        entry.properties.relatives_handicapped_detail7 || '',
      relativesHandicappedDegree7:
        entry.properties.relatives_handicapped_degree7 || '',
      relativesHandicappedOther7:
        entry.properties.relativeshandicappedother7 || '',
      relativesDeliveryDate7: entry.properties.relatives_delivery_date7 || '',
      relativesBereavement7: entry.properties.registration_bereavement7 || '',

      // マル扶 扶養者情報(扶養親族8)
      relativesLastname8: entry.properties.relatives_lastname8 || '',
      relativesLastnameRuby8: entry.properties.relatives_lastname_ruby8 || '',
      relativesFirstname8: entry.properties.relatives_firstname8 || '',
      relativesFirstnameRuby8: entry.properties.relatives_firstname_ruby8 || '',
      relativesBirthday8: entry.properties.relatives_birthday8 || '',
      relativesGender8: entry.properties.relatives_gender8 || '',
      relativesRelationship8: entry.properties.relatives_relationship8 || '',
      relativesSalary8: entry.properties.relatives_salary8 || '',
      relativesPension8: entry.properties.relatives_pension8 || '',
      relativesOtherIncome8: entry.properties.relatives_other_income8 || '',
      relativesLiving8: entry.properties.relatives_living8 || '',
      relativesPostCode8: entry.properties.relatives_post_code8 || '',
      relativesPrefecture8: entry.properties.relatives_prefecture8 || '',
      relativesMunicipalities8:
        entry.properties.relatives_municipalities8 || '',
      relativesTownArea8: entry.properties.relatives_town_area8 || '',
      relativesOtherAddress8: entry.properties.relatives_other_address8 || '',
      relativesHandicapped8: entry.properties.relatives_handicapped8 || '',
      relativesHandicappedDetail8:
        entry.properties.relatives_handicapped_detail8 || '',
      relativesHandicappedDegree8:
        entry.properties.relatives_handicapped_degree8 || '',
      relativesHandicappedOther8:
        entry.properties.relativeshandicappedother8 || '',
      relativesDeliveryDate8: entry.properties.relatives_delivery_date8 || '',
      relativesBereavement8: entry.properties.registration_bereavement8 || '',

      // マル扶 扶養者情報(扶養親族9)
      relativesLastname9: entry.properties.relatives_lastname9 || '',
      relativesLastnameRuby9: entry.properties.relatives_lastname_ruby9 || '',
      relativesFirstname9: entry.properties.relatives_firstname9 || '',
      relativesFirstnameRuby9: entry.properties.relatives_firstname_ruby9 || '',
      relativesBirthday9: entry.properties.relatives_birthday9 || '',
      relativesGender9: entry.properties.relatives_gender9 || '',
      relativesRelationship9: entry.properties.relatives_relationship9 || '',
      relativesSalary9: entry.properties.relatives_salary9 || '',
      relativesPension9: entry.properties.relatives_pension9 || '',
      relativesOtherIncome9: entry.properties.relatives_other_income9 || '',
      relativesLiving9: entry.properties.relatives_living9 || '',
      relativesPostCode9: entry.properties.relatives_post_code9 || '',
      relativesPrefecture9: entry.properties.relatives_prefecture9 || '',
      relativesMunicipalities9:
        entry.properties.relatives_municipalities9 || '',
      relativesTownArea9: entry.properties.relatives_town_area9 || '',
      relativesOtherAddress9: entry.properties.relatives_other_address9 || '',
      relativesHandicapped9: entry.properties.relatives_handicapped9 || '',
      relativesHandicappedDetail9:
        entry.properties.relatives_handicapped_detail9 || '',
      relativesHandicappedDegree9:
        entry.properties.relatives_handicapped_degree9 || '',
      relativesDeliveryDate9: entry.properties.relatives_delivery_date9 || '',
      relativesHandicappedOther9:
        entry.properties.relativeshandicappedother9 || '',
      relativesBereavement9: entry.properties.registration_bereavement9 || '',

      // マル扶 扶養者情報(扶養親族10)
      relativesLastname10: entry.properties.relatives_lastname10 || '',
      relativesLastnameRuby10: entry.properties.relatives_lastname_ruby10 || '',
      relativesFirstname10: entry.properties.relatives_firstname10 || '',
      relativesFirstnameRuby10:
        entry.properties.relatives_firstname_ruby10 || '',
      relativesBirthday10: entry.properties.relatives_birthday10 || '',
      relativesGender10: entry.properties.relatives_gender10 || '',
      relativesRelationship10: entry.properties.relatives_relationship10 || '',
      relativesSalary10: entry.properties.relatives_salary10 || '',
      relativesPension10: entry.properties.relatives_pension10 || '',
      relativesOtherIncome10: entry.properties.relatives_other_income10 || '',
      relativesLiving10: entry.properties.relatives_living10 || '',
      relativesPostCode10: entry.properties.relatives_post_code10 || '',
      relativesPrefecture10: entry.properties.relatives_prefecture10 || '',
      relativesMunicipalities10:
        entry.properties.relatives_municipalities10 || '',
      relativesTownArea10: entry.properties.relatives_town_area10 || '',
      relativesOtherAddress10: entry.properties.relatives_other_address10 || '',
      relativesHandicapped10: entry.properties.relatives_handicapped10 || '',
      relativesHandicappedDetail10:
        entry.properties.relatives_handicapped_detail10 || '',
      relativesHandicappedDegree10:
        entry.properties.relatives_handicapped_degree10 || '',
      relativesHandicappedOther10:
        entry.properties.relativeshandicappedother10 || '',
      relativesDeliveryDate10: entry.properties.relatives_delivery_date10 || '',
      relativesBereavement10: entry.properties.registration_bereavement10 || '',

      //その他入力項目
      columnBDeclaration: entry.properties.column_b_declaration || '',
      carsForCommuting: entry.properties.cars_for_commuting || '',
      commuteStartDay: entry.properties.commute_start_day || '',
      carNumber: entry.properties.car_number || '',
      carNumberS1: entry.properties.car_number_s1 || '',
      carNumberS2: entry.properties.car_number_s2 || '',
      carNumberS3: entry.properties.car_number_s3 || '',
      carNumberS4: entry.properties.car_number_s4 || '',
      carNumberS5: entry.properties.car_number_s5 || '',
      carName: entry.properties.car_name || '',
      carColor: entry.properties.car_color || '',
      applicationDistinction: entry.properties.application_distinction || '',
      carsType: entry.properties.cars_type || '',
      diligenceName: entry.properties.diligence_name || '',
      writtenOathCarsCommute: entry.properties.written_oath_cars_commute || '',

      // DR
      drWrittenOath: entry.properties.dr_written_oath || '',
      drPersonalInformation: entry.properties.dr_personal_information || '',

      // //個人情報・機密情報保護研修
      // training: entry.properties.
      // training1: entry.properties.
      // training2: entry.properties.
      // training3: entry.properties.
      // training4: entry.properties.
      // training5: entry.properties.
      // training6: entry.properties.
      // training7: entry.properties.
      // training8: entry.properties.
      // training9: entry.properties.
      // training10: entry.properties.
      // training11: entry.properties.
      // training12: entry.properties.
      // training13: entry.properties.
      // training14: entry.properties.
      // training15: entry.properties.
      // training16: entry.properties.
      // training17:entry.properties.
      // training18:entry.properties.
      // training19: entry.properties.
      // training20: entry.properties.
      // training21: entry.properties.
    },
    errors: {},
    touched: {},
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const branchCheck =
    branch && !(branch === 'default') ? `&branch=${branch}` : '';

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            {prev === 'top' ? '提出書類の確認' : '入職書類の確認'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ display: prev === 'top' ? '' : 'none' }}>
        <InputLabel
          sx={{
            mt: 3,
            color: '#0f1941',
            fontSize: '1.1em',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {'入職手続き'}
        </InputLabel>
      </div>
      <Box sx={{ m: 2 }}>
        <div>
          <div
            style={{
              border: '1px solid rgba(215,215,215,1)',
              marginTop: '1em',
            }}
          >
            <span>
              <Box
                sx={{
                  mt: 2,
                  ml: 2,
                  display: 'flex',
                  justifyContent: 'left',
                  fontSize: '1em',
                }}
              >
                <div>
                  管理番号：
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: '#e74536',
                    }}
                  >
                    {entry.id}
                  </span>
                </div>
              </Box>
              <Box sx={{ ml: 4 }}>
                <InputLabel
                  sx={{
                    mt: 2,
                    color: '#0f1941',
                    fontSize: '1em',
                    fontWeight: 'bold',
                  }}
                >
                  {'申告者情報'}
                </InputLabel>
                <div>
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'氏名:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      mt: 2,
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.lastname}　${formState.values.firstname}`}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'氏名(フリガナ):'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.lastnameRuby}　${formState.values.firstnameRuby}`}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'生年月日:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.birthday}`}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'性別:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {formState.values.gender === '男性' ? '男性' : '女性'}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'就業開始日:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.startDate}`}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'支店名:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.branch}`}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'スタッフコード:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.staffCode}`}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'障害者区分:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.handicapped}`}
                  </InputLabel>
                </div>
                <span
                  style={{
                    display:
                      formState.values.handicapped === '該当なし' ? 'none' : '',
                  }}
                >
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害内容:'}
                    </InputLabel>
                    <Box
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                        verticalAlign: 'top',
                      }}
                    >
                      {`${formState.values.handicappedDetail}`}
                    </Box>
                  </div>
                  <Divider />
                  <span
                    style={{
                      display:
                        formState.values.handicappedDetail !== 'その他'
                          ? ''
                          : 'none',
                    }}
                  >
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害の程度:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.handicappedDegree}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'交付日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.deliveryDate}`}
                      </InputLabel>
                    </div>
                  </span>
                  <span
                    style={{
                      display:
                        formState.values.handicappedDetail === 'その他'
                          ? ''
                          : 'none',
                    }}
                  >
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害の詳細:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.handicappedOther}`}
                      </InputLabel>
                    </div>
                  </span>
                </span>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'勤労学生区分:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.workingStudent}`}
                  </InputLabel>
                </div>
                <span
                  style={{
                    display:
                      formState.values.workingStudent === '該当なし'
                        ? 'none'
                        : '',
                  }}
                >
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'学校種別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.schoolLabel}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'学校名:'}
                    </InputLabel>
                    <Box
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                        verticalAlign: 'top',
                      }}
                    >
                      {`${formState.values.schoolName}`}
                    </Box>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'入学年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.admissionDate}`}
                    </InputLabel>
                  </div>
                </span>
              </Box>
              <Box sx={{ ml: 4 }}>
                <InputLabel
                  sx={{
                    mt: 2,
                    color: '#0f1941',
                    fontSize: '1em',
                    fontWeight: 'bold',
                  }}
                >
                  {'緊急連絡先'}
                </InputLabel>
                <div>
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'誓約書に同意:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      mt: 2,
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {'同意する'}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'連絡先氏名:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.emergencyLastname}　${formState.values.emergencyFirstname}`}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'続柄:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.emergencyRelationship}`}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'電話番号:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.emergencyTelNumber}`}
                  </InputLabel>
                </div>
              </Box>
              <Box sx={{ ml: 4 }}>
                <InputLabel
                  sx={{
                    mt: 2,
                    color: '#0f1941',
                    fontSize: '1em',
                    fontWeight: 'bold',
                  }}
                >
                  {'現住所'}
                </InputLabel>
                <div>
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'郵便番号:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      mt: 2,
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.postCode1}`}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'都道府県:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.prefecture1}`}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'市区町村:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.municipalities1}`}
                  </InputLabel>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'町域・番地:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {`${formState.values.townArea1}`}
                  </InputLabel>
                </div>
                <span
                  style={{
                    display:
                      formState.values.otherAddress1 === '' ? 'none' : '',
                  }}
                >
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'建物名など:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.otherAddress1}`}
                    </InputLabel>
                  </div>
                </span>
              </Box>
              <Box sx={{ ml: 4 }}>
                <InputLabel
                  sx={{
                    mt: 2,
                    color: '#0f1941',
                    fontSize: '1em',
                    fontWeight: 'bold',
                  }}
                >
                  {'住民票登録住所'}
                </InputLabel>

                <div>
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'現住所との相違:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      mt: 2,
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {formState.values.registrationAddress === '現住所と異なる'
                      ? '異なる'
                      : '同じ'}
                  </InputLabel>
                </div>
                <span
                  style={{
                    display:
                      formState.values.registrationAddress === '現住所と異なる'
                        ? ''
                        : 'none',
                  }}
                >
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'郵便番号:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.postCode2}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'都道府県:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.prefecture2}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'市区町村:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.municipalities2}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'町域・番地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.townArea2}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.otherAddress2 === '' ? 'none' : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'建物名など:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.otherAddress2}`}
                      </InputLabel>
                    </div>
                  </span>
                </span>
              </Box>
              <Box sx={{ ml: 4 }}>
                <InputLabel
                  sx={{
                    mt: 2,
                    color: '#0f1941',
                    fontSize: '1em',
                    fontWeight: 'bold',
                  }}
                >
                  {'甲乙申告'}
                </InputLabel>
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'乙欄申告:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {formState.values.columnBDeclaration === '申告する'
                      ? '申告する'
                      : '申告しない'}
                  </InputLabel>
                </div>
              </Box>
              <Box
                sx={{
                  ml: 4,
                  display:
                    formState.values.columnBDeclaration === '申告しない'
                      ? ''
                      : 'none',
                }}
              >
                <Box>
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'世帯主'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'世帯主の氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.household === '申告者と同じ'
                        ? '申告者と同じ'
                        : '申告者と異なる'}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.household === '申告者と同じ'
                          ? 'none'
                          : '',
                    }}
                  >
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.lastname3}　${formState.values.firstname3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relationship3}`}
                      </InputLabel>
                    </div>
                  </span>
                </Box>
                <Box
                  sx={{
                    display: formState.values.marriage === 'はい' ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'配偶者について'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'結婚:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.marriage === 'いいえ'
                        ? 'していない'
                        : 'している'}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.marriage === 'はい' ? '' : 'none',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'控除申告:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.dependentDeclaration === 'はい'
                          ? 'する'
                          : 'しない'}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.dependentDeclaration === 'はい'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.partnerLastname}　${formState.values.partnerFirstname}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名(フリガナ):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.partnerLastnameRuby}　${formState.values.partnerFirstnameRuby}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'生年月日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.partnerBirthday}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'性別:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {formState.values.partnerGender === '男性'
                            ? '男性'
                            : '女性'}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'収入(給与):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`¥${formState.values.partnerSalary}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'収入(老齢年金):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`¥${formState.values.partnerPension}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'収入(その他収入):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`¥${formState.values.partnerOtherIncome}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'居住地:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.partnerLiving}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.partnerLiving === '別居'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'郵便番号:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.partnerPostCode}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'都道府県:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.partnerPrefecture}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'市区町村:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.partnerMunicipalities}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'町域・番地:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.partnerTownArea}`}
                          </InputLabel>
                        </div>
                        <span
                          style={{
                            display:
                              formState.values.partnerOtherAddress === ''
                                ? 'none'
                                : '',
                          }}
                        >
                          <Divider />
                          <div>
                            <InputLabel
                              sx={{
                                color: '#0f1941',
                                fontSize: '0.9em',
                                width: '40%',
                                textAlign: 'right',
                                display: 'inline-block',
                              }}
                            >
                              {'建物名など:'}
                            </InputLabel>
                            <InputLabel
                              sx={{
                                ml: 2,
                                color: '#0f1941',
                                fontSize: '0.9em',
                                textDecoration: 'underline',
                                width: '50%',
                                display: 'inline-block',
                              }}
                            >
                              {`${formState.values.partnerOtherAddress}`}
                            </InputLabel>
                          </div>
                        </span>
                      </span>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害者区分:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.partnerHandicapped}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.partnerHandicapped === '該当なし'
                              ? 'none'
                              : '',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害内容:'}
                          </InputLabel>
                          <Box
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            {`${formState.values.partnerHandicappedDetail}`}
                          </Box>
                        </div>
                        <Divider />
                        <span
                          style={{
                            display:
                              formState.values.partnerHandicappedDetail !==
                              'その他'
                                ? ''
                                : 'none',
                          }}
                        >
                          <div>
                            <InputLabel
                              sx={{
                                color: '#0f1941',
                                fontSize: '0.9em',
                                width: '40%',
                                textAlign: 'right',
                                display: 'inline-block',
                              }}
                            >
                              {'障害の程度:'}
                            </InputLabel>
                            <InputLabel
                              sx={{
                                ml: 2,
                                color: '#0f1941',
                                fontSize: '0.9em',
                                textDecoration: 'underline',
                                width: '50%',
                                display: 'inline-block',
                              }}
                            >
                              {`${formState.values.partnerHandicappedDegree}`}
                            </InputLabel>
                          </div>
                          <Divider />
                          <div>
                            <InputLabel
                              sx={{
                                color: '#0f1941',
                                fontSize: '0.9em',
                                width: '40%',
                                textAlign: 'right',
                                display: 'inline-block',
                              }}
                            >
                              {'交付日:'}
                            </InputLabel>
                            <InputLabel
                              sx={{
                                ml: 2,
                                color: '#0f1941',
                                fontSize: '0.9em',
                                textDecoration: 'underline',
                                width: '50%',
                                display: 'inline-block',
                              }}
                            >
                              {`${formState.values.partnerDeliveryDate}`}
                            </InputLabel>
                          </div>
                        </span>
                        <span
                          style={{
                            display:
                              formState.values.partnerHandicappedDetail ===
                              'その他'
                                ? ''
                                : 'none',
                          }}
                        >
                          <div>
                            <InputLabel
                              sx={{
                                color: '#0f1941',
                                fontSize: '0.9em',
                                width: '40%',
                                textAlign: 'right',
                                display: 'inline-block',
                              }}
                            >
                              {'障害の詳細:'}
                            </InputLabel>
                            <InputLabel
                              sx={{
                                ml: 2,
                                color: '#0f1941',
                                fontSize: '0.9em',
                                textDecoration: 'underline',
                                width: '50%',
                                display: 'inline-block',
                              }}
                            >
                              {`${formState.values.partnerHandicappedOther}`}
                            </InputLabel>
                          </div>
                        </span>
                      </span>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'当年死別:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {formState.values.partnerBereavement === '該当しない'
                            ? 'いいえ'
                            : 'はい'}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                </Box>
                <Box
                  sx={{
                    display:
                      formState.values.marriage === 'いいえ' ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'寡婦/ひとり親について'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                        verticalAlign: 'top',
                      }}
                    >
                      {'状況:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                        whiteSpace: 'normal',
                      }}
                    >
                      {`${formState.values.situationSelection}`}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: formState.values.relativesLastname1 ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'扶養親族1'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastname1}　${formState.values.relativesFirstname1}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名(フリガナ):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastnameRuby1}　${formState.values.relativesFirstnameRuby1}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'生年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesBirthday1}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'性別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesGender1}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'続柄:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesRelationship1}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(給与):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesSalary1}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(老齢年金):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesPension1}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(その他収入):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesOtherIncome1}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'居住地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLiving1}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesLiving1 === '別居'
                          ? ''
                          : 'none',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'郵便番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPostCode1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'都道府県:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPrefecture1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'市区町村:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesMunicipalities1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'町域・番地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesTownArea1}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesOtherAddress1 === ''
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'建物名など:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesOtherAddress1}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害者区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesHandicapped1}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesHandicapped1 === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害内容:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.relativesHandicappedDetail1}`}
                      </Box>
                    </div>
                    <Divider />
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail1 !==
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の程度:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${
                            formState.values.relativesHandicappedDegree1 || ''
                          }`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'交付日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesDeliveryDate1}`}
                        </InputLabel>
                      </div>
                    </span>

                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail1 ===
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の詳細:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${
                            formState.values.relativesHandicappedOther1 || ''
                          }`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'当年死別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.relativesBereavement1 === '該当しない'
                        ? 'いいえ'
                        : 'はい'}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: formState.values.relativesLastname2 ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'扶養親族2'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastname2}　${formState.values.relativesFirstname2}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名(フリガナ):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastnameRuby2}　${formState.values.relativesFirstnameRuby2}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'生年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesBirthday2}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'性別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesGender2}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'続柄:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesRelationship2}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(給与):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesSalary2}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(老齢年金):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesPension2}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(その他収入):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesOtherIncome2}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'居住地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLiving2}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesLiving2 === '別居'
                          ? ''
                          : 'none',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'郵便番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPostCode2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'都道府県:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPrefecture2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'市区町村:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesMunicipalities2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'町域・番地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesTownArea2}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesOtherAddress2 === ''
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'建物名など:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesOtherAddress2}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害者区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesHandicapped2}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesHandicapped2 === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害内容:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.relativesHandicappedDetail2}`}
                      </Box>
                    </div>
                    <Divider />
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail2 !==
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の程度:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${
                            formState.values.relativesHandicappedDegree2 || ''
                          }`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'交付日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesDeliveryDate2}`}
                        </InputLabel>
                      </div>
                    </span>

                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail2 ===
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の詳細:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedOther2}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'当年死別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.relativesBereavement2 === '該当しない'
                        ? 'いいえ'
                        : 'はい'}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: formState.values.relativesLastname3 ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'扶養親族3'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastname3}　${formState.values.relativesFirstname3}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名(フリガナ):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastnameRuby3}　${formState.values.relativesFirstnameRuby3}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'生年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesBirthday3}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'性別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesGender3}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'続柄:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesRelationship3}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(給与):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesSalary3}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(老齢年金):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesPension3}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(その他収入):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesOtherIncome3}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'居住地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLiving3}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesLiving3 === '別居'
                          ? ''
                          : 'none',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'郵便番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPostCode3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'都道府県:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPrefecture3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'市区町村:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesMunicipalities3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'町域・番地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesTownArea3}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesOtherAddress3 === ''
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'建物名など:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesOtherAddress3}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害者区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesHandicapped3}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesHandicapped3 === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害内容:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.relativesHandicappedDetail3}`}
                      </Box>
                    </div>
                    <Divider />
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail3 !==
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の程度:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDegree3}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'交付日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesDeliveryDate3}`}
                        </InputLabel>
                      </div>
                    </span>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail3 ===
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の詳細:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedOther3}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'当年死別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.relativesBereavement3 === '該当しない'
                        ? 'いいえ'
                        : 'はい'}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: formState.values.relativesLastname4 ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'扶養親族4'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastname4}　${formState.values.relativesFirstname4}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名(フリガナ):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastnameRuby4}　${formState.values.relativesFirstnameRuby4}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'生年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesBirthday4}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'性別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesGender4}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'続柄:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesRelationship4}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(給与):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesSalary4}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(老齢年金):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesPension4}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(その他収入):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesOtherIncome4}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'居住地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLiving4}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesLiving4 === '別居'
                          ? ''
                          : 'none',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'郵便番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPostCode4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'都道府県:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPrefecture4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'市区町村:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesMunicipalities4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'町域・番地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesTownArea4}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesOtherAddress4 === ''
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'建物名など:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesOtherAddress4}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害者区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesHandicapped4}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesHandicapped4 === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害内容:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.relativesHandicappedDetail4}`}
                      </Box>
                    </div>
                    <Divider />
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail4 !==
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の程度:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDegree4}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'交付日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesDeliveryDate4}`}
                        </InputLabel>
                      </div>
                    </span>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail3 ===
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の詳細:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedOther3}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'当年死別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.relativesBereavement4 === '該当しない'
                        ? 'いいえ'
                        : 'はい'}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: formState.values.relativesLastname5 ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'扶養親族5'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastname5}　${formState.values.relativesFirstname5}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名(フリガナ):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastnameRuby5}　${formState.values.relativesFirstnameRuby5}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'生年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesBirthday5}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'性別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesGender5}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'続柄:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesRelationship5}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(給与):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesSalary5}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(老齢年金):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesPension5}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(その他収入):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesOtherIncome5}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'居住地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLiving5}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesLiving5 === '別居'
                          ? ''
                          : 'none',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'郵便番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPostCode5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'都道府県:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPrefecture5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'市区町村:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesMunicipalities5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'町域・番地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesTownArea5}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesOtherAddress5 === ''
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'建物名など:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesOtherAddress5}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害者区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesHandicapped5}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesHandicapped5 === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害内容:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.relativesHandicappedDetail5}`}
                      </Box>
                    </div>
                    <Divider />
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail5 !==
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の程度:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDegree5}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'交付日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesDeliveryDate5}`}
                        </InputLabel>
                      </div>
                    </span>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail5 ===
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の詳細:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedOther5}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'当年死別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.relativesBereavement5 === '該当しない'
                        ? 'いいえ'
                        : 'はい'}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: formState.values.relativesLastname6 ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'扶養親族6'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastname6}　${formState.values.relativesFirstname6}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名(フリガナ):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastnameRuby6}　${formState.values.relativesFirstnameRuby6}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'生年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesBirthday6}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'性別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesGender6}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'続柄:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesRelationship6}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(給与):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesSalary6}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(老齢年金):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesPension6}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(その他収入):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesOtherIncome6}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'居住地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLiving6}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesLiving6 === '別居'
                          ? ''
                          : 'none',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'郵便番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPostCode6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'都道府県:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPrefecture6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'市区町村:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesMunicipalities6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'町域・番地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesTownArea6}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesOtherAddress6 === ''
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'建物名など:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesOtherAddress6}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害者区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesHandicapped6}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesHandicapped6 === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害内容:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.relativesHandicappedDetail6}`}
                      </Box>
                    </div>
                    <Divider />
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail6 !==
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の程度:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDegree6}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'交付日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesDeliveryDate6}`}
                        </InputLabel>
                      </div>
                    </span>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail6 ===
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の詳細:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedOther6}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'当年死別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.relativesBereavement6 === '該当しない'
                        ? 'いいえ'
                        : 'はい'}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: formState.values.relativesLastname7 ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'扶養親族7'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastname7}　${formState.values.relativesFirstname7}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名(フリガナ):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastnameRuby7}　${formState.values.relativesFirstnameRuby7}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'生年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesBirthday7}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'性別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesGender7}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'続柄:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesRelationship7}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(給与):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesSalary7}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(老齢年金):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesPension7}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(その他収入):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesOtherIncome7}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'居住地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLiving7}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesLiving7 === '別居'
                          ? ''
                          : 'none',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'郵便番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPostCode7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'都道府県:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPrefecture7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'市区町村:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesMunicipalities7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'町域・番地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesTownArea7}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesOtherAddress7 === ''
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'建物名など:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesOtherAddress7}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害者区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesHandicapped7}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesHandicapped7 === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害内容:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.relativesHandicappedDetail7}`}
                      </Box>
                    </div>
                    <Divider />
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail7 !==
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の程度:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDegree7}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'交付日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesDeliveryDate7}`}
                        </InputLabel>
                      </div>
                    </span>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail7 ===
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の詳細:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedOther7}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'当年死別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.relativesBereavement7 === '該当しない'
                        ? 'いいえ'
                        : 'はい'}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: formState.values.relativesLastname8 ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'扶養親族8'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastname8}　${formState.values.relativesFirstname8}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名(フリガナ):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastnameRuby8}　${formState.values.relativesFirstnameRuby8}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'生年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesBirthday8}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'性別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesGender8}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'続柄:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesRelationship8}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(給与):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesSalary8}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(老齢年金):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesPension8}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(その他収入):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesOtherIncome8}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'居住地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLiving8}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesLiving8 === '別居'
                          ? ''
                          : 'none',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'郵便番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPostCode8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'都道府県:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPrefecture8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'市区町村:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesMunicipalities8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'町域・番地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesTownArea8}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesOtherAddress8 === ''
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'建物名など:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesOtherAddress8}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害者区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesHandicapped8}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesHandicapped8 === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害内容:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.relativesHandicappedDetail8}`}
                      </Box>
                    </div>
                    <Divider />
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail8 !==
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の程度:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDegree8}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'交付日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesDeliveryDate8}`}
                        </InputLabel>
                      </div>
                    </span>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail8 ===
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の詳細:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedOther8}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'当年死別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.relativesBereavement8 === '該当しない'
                        ? 'いいえ'
                        : 'はい'}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: formState.values.relativesLastname9 ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'扶養親族9'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastname9}　${formState.values.relativesFirstname9}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名(フリガナ):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastnameRuby9}　${formState.values.relativesFirstnameRuby9}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'生年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesBirthday9}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'性別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesGender9}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'続柄:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesRelationship9}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(給与):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesSalary9}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(老齢年金):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesPension9}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(その他収入):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesOtherIncome9}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'居住地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLiving9}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesLiving9 === '別居'
                          ? ''
                          : 'none',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'郵便番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPostCode9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'都道府県:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPrefecture9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'市区町村:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesMunicipalities9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'町域・番地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesTownArea9}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesOtherAddress9 === ''
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'建物名など:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesOtherAddress9}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害者区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesHandicapped9}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesHandicapped9 === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害内容:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.relativesHandicappedDetail9}`}
                      </Box>
                    </div>
                    <Divider />
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail9 !==
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の程度:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDegree9}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'交付日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesDeliveryDate9}`}
                        </InputLabel>
                      </div>
                    </span>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail9 ===
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の詳細:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedOther9}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'当年死別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.relativesBereavement9 === '該当しない'
                        ? 'いいえ'
                        : 'はい'}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: formState.values.relativesLastname10 ? '' : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'扶養親族10'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastname10}　${formState.values.relativesFirstname10}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名(フリガナ):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLastnameRuby10}　${formState.values.relativesFirstnameRuby10}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'生年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesBirthday10}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'性別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesGender10}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'続柄:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesRelationship10}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(給与):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesSalary10}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(老齢年金):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesPension10}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'収入(その他収入):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`¥${formState.values.relativesOtherIncome10}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'居住地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesLiving10}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesLiving10 === '別居'
                          ? ''
                          : 'none',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'郵便番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPostCode10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'都道府県:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesPrefecture10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'市区町村:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesMunicipalities10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'町域・番地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesTownArea10}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesOtherAddress10 === ''
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'建物名など:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesOtherAddress10}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害者区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.relativesHandicapped10}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.relativesHandicapped10 === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害内容:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.relativesHandicappedDetail10}`}
                      </Box>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail10 !==
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の程度:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDegree10}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'交付日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesDeliveryDate10}`}
                        </InputLabel>
                      </div>
                    </span>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicappedDetail2 ===
                          'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の詳細:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesHandicappedOther2}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'当年死別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.relativesBereavement10 === '該当しない'
                        ? 'いいえ'
                        : 'はい'}
                    </InputLabel>
                  </div>
                </Box>
              </Box>
              <Box sx={{ ml: 4 }}>
                <InputLabel
                  sx={{
                    mt: 2,
                    color: '#0f1941',
                    fontSize: '1em',
                    fontWeight: 'bold',
                  }}
                >
                  {'その他の申告'}
                </InputLabel>
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'車輌通勤許可申請:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {formState.values.carsForCommuting === '申請する'
                      ? '申請する'
                      : '申請しない'}
                  </InputLabel>
                </div>
                <Box
                  style={{
                    display:
                      formState.values.carsForCommuting === '申請する'
                        ? ''
                        : 'none',
                  }}
                >
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'通勤開始日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.commuteStartDay}
                    </InputLabel>
                  </div>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'車輌番号（地域名）:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.carNumberS1}
                    </InputLabel>
                  </div>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'車輌番号（分類番号）:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.carNumberS2}
                    </InputLabel>
                  </div>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'車輌番号（ひらがな）:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.carNumberS3}
                    </InputLabel>
                  </div>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'車輌番号（連番）:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.carNumber}
                    </InputLabel>
                  </div>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'車名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.carName}
                    </InputLabel>
                  </div>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'車の色:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.carColor}
                    </InputLabel>
                  </div>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'申請区分(車輌通勤):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.applicationDistinction}
                    </InputLabel>
                  </div>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'車輌種別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.carsType}
                    </InputLabel>
                  </div>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'勤務先名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.diligenceName}
                    </InputLabel>
                  </div>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'車輌通勤許可誓約書:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.writtenOathCarsCommute}
                    </InputLabel>
                  </div>
                </Box>
              </Box>
              <Box sx={{ ml: 4 }}>
                <InputLabel
                  sx={{
                    mt: 2,
                    color: '#0f1941',
                    fontSize: '1em',
                    fontWeight: 'bold',
                  }}
                >
                  {'個人情報機密情報保護研修'}
                </InputLabel>
                <div>
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '40%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {/*{'個人情報機密情報保護研修:'}*/}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '50%',
                      display: 'inline-block',
                    }}
                  >
                    {'修学済み'}
                  </InputLabel>
                </div>
                <br />
              </Box>
              <Box
                sx={{
                  display:
                    formState.values.branch ===
                      'DR北関東支店（DR小山課/DR高崎課）' ||
                    formState.values.branch ===
                      'DR東京・千葉支店（DR新宿課/DR船橋課）' ||
                    formState.values.branch ===
                      'DR埼玉支店（DRさいたま課/DR所沢課）' ||
                    formState.values.branch ===
                      'DR神奈川支店（DR横浜課/DR厚木課）' ||
                    formState.values.branch ===
                      'DR名阪支店（DR名古屋課/DR大阪課）'
                      ? ''
                      : 'none',
                  ml: 4,
                }}
              >
                <InputLabel
                  sx={{
                    color: '#0f1941',
                    fontSize: '1em',
                    fontWeight: 'bold',
                  }}
                >
                  {'ドライバー業務について'}
                </InputLabel>
                <div
                  style={{
                    display:
                      formState.values.drWrittenOath === '同意する'
                        ? ''
                        : 'none',
                  }}
                >
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '65%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'運転の安全確保の為の確認書:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '20%',
                        display: 'inline-block',
                      }}
                    >
                      {'同意する'}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '65%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'運転の安全確保の為の誓約書:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '20%',
                        display: 'inline-block',
                      }}
                    >
                      {'同意する'}
                    </InputLabel>
                  </div>
                </div>
                <div
                  style={{
                    display:
                      formState.values.drWrittenOath === '同意する'
                        ? 'none'
                        : '',
                  }}
                >
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '65%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'運転業務ではない:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '20%',
                        display: 'inline-block',
                      }}
                    >
                      {'はい'}
                    </InputLabel>
                  </div>
                </div>
                <Divider />
                <div
                  style={{
                    display:
                      formState.values.drPersonalInformation === '同意する'
                        ? ''
                        : 'none',
                  }}
                >
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '65%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'個人情報提供承諾のお願い:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '20%',
                      display: 'inline-block',
                    }}
                  >
                    {'同意する'}
                  </InputLabel>
                </div>
                <div
                  style={{
                    display:
                      formState.values.drPersonalInformation === '同意する'
                        ? 'none'
                        : '',
                  }}
                >
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '0.9em',
                      width: '65%',
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    {'個人情報の提供予定なし:'}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      ml: 2,
                      color: '#0f1941',
                      fontSize: '0.9em',
                      textDecoration: 'underline',
                      width: '20%',
                      display: 'inline-block',
                    }}
                  >
                    {'はい'}
                  </InputLabel>
                </div>
                <br />
              </Box>
            </span>
          </div>
          {/*<Box sx={{ mt: 9 }} />*/}
        </div>
      </Box>
      <Grid
        item
        xs={7}
        style={{
          textAlign: 'center',
        }}
      >
        <AppBar
          position="fixed"
          color="primary"
          sx={{
            top: 'auto',
            bottom: -2,
            backgroundColor: '#1d6ad4',
          }}
        >
          <Grid
            container
            style={{
              height: 55,
            }}
          >
            <React.Fragment>
              <Grid
                item
                xs={6}
                style={{
                  // backgroundColor: activeStep === 0 ? '#d9d9d9' : '#ff3300',
                  backgroundColor: '#d9d9d9',
                  textAlign: 'center',
                }}
              >
                <Button
                  color="inherit"
                  aria-label="back key"
                  sx={{
                    backgroundColor: '#d9d9d9',
                    marginTop: 1,
                    width: '100%',
                    color: '#1d6ad4',
                  }}
                  onClick={() => {
                    const limit = moment(entry.createdAt)
                      .add(1, 'days')
                      .startOf('day');
                    // console.log(limit);

                    const now = moment();
                    // console.log(now);

                    if (now.isBefore(limit)) {
                      if (prev === 'top') {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=edit' +
                          branchCheck;
                      } else {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?edit=true' +
                          branchCheck;
                      }
                    } else {
                      handleOpen();
                    }
                  }}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    編集する
                  </span>
                </Button>
              </Grid>
              <Grid
                item
                // sx={{ width: '100%' }}
                xs={6}
                style={{
                  textAlign: 'center',
                }}
              >
                <Button
                  color="inherit"
                  aria-label="next key"
                  sx={{
                    marginTop: 1,
                    width: '100%',
                  }}
                  /*disabled={applied}*/
                  onClick={() => {
                    liff.closeWindow();
                  }}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {'閉じる'}
                  </span>
                </Button>
              </Grid>
            </React.Fragment>
          </Grid>
          {/*</Toolbar>*/}
        </AppBar>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            申請が完了しているため編集できません。
            <br />
            変更をご希望の場合は、担当コンサルタントへご連絡ください。
            <br />
            <br />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                sx={{ fontSize: '13px', padding: 0 }}
                onClick={() => {
                  liff.openWindow({
                    url:
                      branch != 'cs'
                        ? `https://www.randstad.co.jp/OCLSTF0000.do?uid=${formState.values.staffCode}&pid=line`
                        : 'https://reg34.smp.ne.jp/regist/is?SMPFORM=qflf-mbodmi-169c8390f88154fb8bcb9cc4ad5c8681',
                    external: true,
                  });
                }}
              >
                給与口座の登録 / 変更希望の場合はこちら
              </Button>
              {branch != 'cs' && (
                <span
                  style={{
                    display:
                      formState.values.carsForCommuting === '申請する'
                        ? ''
                        : 'none',
                  }}
                >
                  <br />
                  <Button
                    sx={{ fontSize: '13px', padding: 0 }}
                    onClick={async () => {
                      const context: any = liff.getContext();
                      const response = await axios.get(
                        `https://asia-northeast1-little-help-connect.cloudfunctions.net/getLiquidUrl?uid=${
                          formState.values.staffCode
                        }&liffid=${context ? context.liffId : ''}`,
                      );
                      console.log(response.data);

                      liff.openWindow({
                        url: response.data.application_url,
                        external: true,
                      });
                    }}
                  >
                    車輌通勤許可申請書の添付書類の
                    <br />
                    アップロードはこちら
                  </Button>
                </span>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};

export default EntryConfirm;
