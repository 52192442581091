import * as React from 'react';
import { useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import liff from '@line/liff';
import {
  isLoadedInsurance,
  retrieveInsurance,
  selectInsurance,
} from '../features/socialInsurance/socialInsuranceSlice';
import Box from '@mui/material/Box';
import { CookiesProvider } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import SocialInsuranceForm from '../components/socialInsuranceForm';
import SocialInsuranceConfirm from '../components/socialInsuranceConfirm';
import {
  isLoaded,
  retrieveProfile,
  selectProfile,
} from '../features/profile/profileSlice';

export default function SocialInsurance() {
  const dispatch = useAppDispatch();
  const profile: any = useAppSelector(selectProfile);
  const insurance: any = useAppSelector(selectInsurance);
  const loadedInsurance = useAppSelector(isLoadedInsurance);
  const loaded = useAppSelector(isLoaded);

  let edit = false;
  const confirm = false;
  let branch = 'default';
  const next = 'default';
  const prev = 'default';
  try {
    const search = useLocation().search;
    const query = queryString.parse(search);
    edit = String(query['edit']) === 'true';
    branch =
      String(query['branch']) === 'cs' ? String(query['branch']) : 'default';
  } catch (e) {
    console.error(e);
  }

  console.log(branch);

  useEffect(() => {
    document.title = '社会保険・雇用保険加入手続き';

    if (!loadedInsurance) {
      dispatch(
        retrieveInsurance({
          userId: userId(),
        }),
      );
    }
    if (!loaded) {
      dispatch(
        retrieveProfile({
          userId: userId(),
        }),
      );
    }
  }, [loadedInsurance]);

  const [open, setOpen] = React.useState(!userId());
  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  return (
    <span>
      {!loadedInsurance || !loaded ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : insurance ? (
        edit ? (
          <CookiesProvider>
            <SocialInsuranceForm
              profile={profile}
              insurance={insurance}
              edit={edit}
              branch={branch}
              next={next}
              confirm={confirm}
            />
          </CookiesProvider>
        ) : (
          <SocialInsuranceConfirm insurance={insurance} prev={prev} />
        )
      ) : (
        <CookiesProvider>
          <SocialInsuranceForm
            profile={profile}
            insurance={insurance}
            edit={edit}
            branch={branch}
            next={next}
            confirm={confirm}
          />
        </CookiesProvider>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            スマートフォン版LINEでご利用になれます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
