import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';

import liff from '@line/liff';
import { answerStartup } from '../features/training/trainingSlice';
import { userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { MobileDatePicker } from '@mui/x-date-pickers';

// @formatter:off

class TrainingFormProps {
  profile: any;
  type: string | undefined;
}

const TrainingForm: React.FC<TrainingFormProps> = ({ profile, type }) => {
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      lastname: profile.lastname,
      firstname: profile.firstname,
      staffCode: profile.staffCode,
      segment: '',
      training: '',
      checkDate: Date.parse(moment().format('YYYY-MM-DD')),
      company: '',
      branch: '',
      manager: '',
      employmentType: '',
    },
    errors: {},
    touched: {},
  });

  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState<{
    [k: number]: boolean;
  }>({});

  let steps = ['1', '2', '3', '4', '5', '6', '7', '8'];

  if (type === 'outsourcing') {
    steps = ['1', '2', '3', '4'];
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const dispatch = useAppDispatch();
  const [creating, setCreating] = React.useState(false);
  const submit = () => {
    if (formState.values.training === '') {
      setErrMsg(
        '『理解しました』のチェックボックスにチェックを入れてください。',
      );
      handleOpen();
      return;
    } else if (formState.values.company === '') {
      setErrMsg('『ご就職先企業名』を選択してください。');
      handleOpen();
      return;
    } else if (formState.values.branch === '') {
      setErrMsg('『支店名』を選択してください。');
      handleOpen();
      return;
    } else if (formState.values.manager === '') {
      setErrMsg('『営業担当者名』を選択してください。');
      handleOpen();
      return;
    }

    if (type === 'outsourcing') {
      formState.values.employmentType = '請負・委託での就業';
    } else {
      formState.values.employmentType = '派遣での就業';
    }

    console.log(formState.values);
    // setCreating(true);
    dispatch(
      answerStartup({
        userId: userId(),
        values: formState.values,
      }),
    );
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo({
      top: 0,
      // behavior: 'smooth',
    });

    const text = `【雇入れ時教育訓練：受講完了】
実施日: ${moment().format('YYYY/MM/DD')}
受講セグメント: ${formState.values.segment}
就業先企業名: ${formState.values.company}
支店名: ${formState.values.branch}
営業担当者名: ${formState.values.manager}`;

    console.log(text);

    liff
      .sendMessages([
        {
          type: 'text',
          text,
        },
      ])
      .then(() => {
        console.log('message sent');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [errMsg, setErrMsg] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (
      formState.values.segment === '' &&
      ((activeStep === 4 && type === 'default') ||
        (activeStep === 0 && type === 'outsourcing'))
    ) {
      setErrMsg('『職種』を選択してください。');
      handleOpen();
      return;
    }

    console.log(activeStep);

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    window.scrollTo({
      top: 0,
    });
  };
  const handleBack = () => {
    console.log(activeStep);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <span>
      {/*<AppBar*/}
      {/*  position="static"*/}
      {/*  elevation={0}*/}
      {/*  style={{*/}
      {/*    alignItems: 'center',*/}
      {/*    backgroundColor: '#6ba5b4',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Toolbar>*/}
      {/*    <Typography variant="h6" component="div">*/}
      {/*      {activeStep === 0 ? '超短め（１：１）' : ''}*/}
      {/*      {activeStep === 1 ? 'ちょい短め（１：２）' : ''}*/}
      {/*      {activeStep === 2 ? '標準（１：４）' : ''}*/}
      {/*      {activeStep === 3 ? '長め（１：１０）' : ''}*/}
      {/*      {activeStep === 4 ? '長め（１：１０）' : ''}*/}
      {/*    </Typography>*/}
      {/*  </Toolbar>*/}
      {/*</AppBar>*/}
      {creating ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ m: 2 }}>
          {activeStep === 0 && type === 'default' ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/images/training/ranstad_LINE_SVG_1.svg"
                    alt="startup"
                    width="100%"
                  />
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 1 && type === 'default' ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/images/training/ranstad_LINE_SVG_2.svg"
                    alt="startup"
                    width="100%"
                  />
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 2 && type === 'default' ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/images/training/ranstad_LINE_SVG_3.svg"
                    alt="startup"
                    width="100%"
                  />
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 3 && type === 'default' ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/images/training/ranstad_LINE_SVG_4-1.svg"
                    alt="startup"
                    width="100%"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/images/training/ranstad_LINE_SVG_4-2.svg"
                    alt="startup"
                    width="100%"
                  />
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {(activeStep === 4 && type === 'default') ||
          (activeStep === 0 && type === 'outsourcing') ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/images/training/ranstad_LINE_SVG_5.svg"
                    alt="startup"
                    width="100%"
                  />
                </div>
                <div
                  style={{
                    paddingLeft: '1em',
                  }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={formState.values.segment}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'segment', event.target.value);
                    }}
                    sx={{
                      display: 'inline-block',
                      mt: 2,
                      mb: 3,
                    }}
                  >
                    <div>
                      <FormControlLabel
                        value="オフィス系（事務、コールセンターなど）"
                        control={<Radio />}
                        label={
                          <span style={{ fontSize: '0.9rem' }}>
                            オフィス系（事務、コールセンターなど）
                          </span>
                        }
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        value="接客販売系"
                        control={<Radio />}
                        label={
                          <span style={{ fontSize: '0.9rem' }}>接客販売系</span>
                        }
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        value="製造系"
                        control={<Radio />}
                        label={
                          <span style={{ fontSize: '0.9rem' }}>製造系</span>
                        }
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        value="物流系"
                        control={<Radio />}
                        label={
                          <span style={{ fontSize: '0.9rem' }}>物流系</span>
                        }
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        value="自動車運転業務"
                        control={<Radio />}
                        label={
                          <span style={{ fontSize: '0.9rem' }}>
                            自動車運転業務
                          </span>
                        }
                      />
                    </div>
                  </RadioGroup>
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {(activeStep === 5 && type === 'default') ||
          (activeStep === 1 && type === 'outsourcing') ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/images/training/ranstad_LINE_SVG_6-1.svg"
                    alt="startup"
                    width="100%"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/images/training/ranstad_LINE_SVG_6-2.svg"
                    alt="startup"
                    width="100%"
                  />
                </div>
                <div
                  style={{
                    display:
                      formState.values.segment ===
                      'オフィス系（事務、コールセンターなど）'
                        ? ''
                        : 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_7.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display:
                      formState.values.segment === '製造系' ||
                      formState.values.segment === '物流系'
                        ? ''
                        : 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_8-1.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_8-2.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_8-3.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display:
                      formState.values.segment === '自動車運転業務'
                        ? ''
                        : 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_9-1.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_9-2.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_9-3.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_9-4.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/images/training/ranstad_LINE_SVG_10-1.svg"
                    alt="startup"
                    width="100%"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/images/training/ranstad_LINE_SVG_10-2.svg"
                    alt="startup"
                    width="100%"
                  />
                </div>
                <div
                  style={{
                    display:
                      formState.values.segment === '製造系' ? '' : 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_11-1.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_11-2.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display:
                      formState.values.segment === '物流系' ? '' : 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_11-3.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_11-4.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display:
                      formState.values.segment === '接客販売系' ? '' : 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_12-1.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display:
                      formState.values.segment ===
                      'オフィス系（事務、コールセンターなど）'
                        ? ''
                        : 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_12-2.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display:
                      formState.values.segment === '自動車運転業務'
                        ? ''
                        : 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_13-1.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_13-2.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_13-3.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_13-4.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/training/ranstad_LINE_SVG_13-5.svg"
                      alt="startup"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {(activeStep === 6 && type === 'default') ||
          (activeStep === 2 && type === 'outsourcing') ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      border: '1px solid rgb(103,154,246)',
                      borderRadius: '7px',
                    }}
                  >
                    <FormLabel
                      id="gendar-label"
                      style={{
                        color: '#0f1941',
                        fontWeight: 'bold',
                        padding: '6px 0px',
                      }}
                    >
                      <span
                        style={{
                          display: type === 'default' ? '' : 'none',
                        }}
                      >
                        スタートアップ研修・安全衛生教育
                        <br />
                        実施報告書
                      </span>
                      <span
                        style={{
                          display: type === 'default' ? 'none' : '',
                        }}
                      >
                        安全衛生教育 実施報告書
                      </span>
                    </FormLabel>
                  </div>
                  <Box
                    sx={{
                      mt: 4,
                      ml: 1,
                      mb: 5,
                      mr: 1,
                      fontSize: '0.9em',
                    }}
                  >
                    お疲れ様でした。
                    <br />
                    研修内容を理解した方は、「理解しました」のチェックボックスにチェックを入れて頂き、報告内容を入力・確認のうえ、受講を完了してください。
                  </Box>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Checkbox
                      checked={formState.values.training === 'true'}
                      onChange={(event) => {
                        handleChange(
                          event,
                          'training',
                          formState.values.training === 'true'
                            ? 'false'
                            : 'true',
                        );
                      }}
                    />
                    <span
                      style={{
                        fontSize: '0.8em',
                        display: type === 'default' ? '' : 'none',
                      }}
                    >
                      「スタートアップ研修」、
                      <br />
                      「雇入れ時安全衛生教育」を理解しました
                    </span>
                    <span
                      style={{
                        paddingTop: '.8em',
                        fontSize: '0.8em',
                        display: type === 'default' ? 'none' : '',
                      }}
                    >
                      「雇入れ時安全衛生教育」を理解しました
                    </span>
                  </div>
                  <div>
                    <FormLabel
                      id="gendar-label"
                      sx={{
                        mt: 6,
                        ml: 1,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '35%',
                        textAlign: 'left',
                        display: 'inline-block',
                      }}
                    >
                      実施日
                    </FormLabel>
                    <MobileDatePicker
                      inputFormat="yyyy/M/d"
                      toolbarFormat="yyyy/M/d"
                      value={moment().format('yyyy/M/D')}
                      onChange={(date) => {
                        console.log(date);
                        handleChange(
                          {
                            target: {
                              name: 'checkDate',
                            },
                          },
                          'checkDate',
                          moment(date).format('YYYY-MM-DD'),
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          sx={{ mt: 5, ml: 1, mr: 0, mb: 0, width: '55%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...params}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <FormLabel
                      id="gendar-label"
                      sx={{
                        mt: 3,
                        ml: 1,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '35%',
                        textAlign: 'left',
                        display: 'inline-block',
                      }}
                    >
                      スタッフコード
                    </FormLabel>
                    <TextField
                      id="staff-code"
                      variant="standard"
                      type="tel"
                      placeholder="00000000"
                      value={formState.values.staffCode}
                      inputProps={{ maxLength: '8' }}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(event, 'staffCode', event.target.value);
                      }}
                      sx={{
                        mt: 2,
                        ml: 1,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '55%',
                        display: 'inline-block',
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div>
                    <FormLabel
                      id="gendar-label"
                      sx={{
                        mt: 3,
                        ml: 1,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '35%',
                        textAlign: 'left',
                        display: 'inline-block',
                      }}
                    >
                      お名前
                    </FormLabel>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      value={formState.values.lastname}
                      onChange={(event) => {
                        handleChange(event, 'lastname', event.target.value);
                      }}
                      sx={{ mt: 2, ml: 1, mr: 0, mb: 2, width: '26%' }}
                      placeholder="山田"
                    />
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      value={formState.values.firstname}
                      onChange={(event) => {
                        handleChange(event, 'firstname', event.target.value);
                      }}
                      sx={{ mt: 2, ml: 1, mr: 2, mb: 2, width: '26%' }}
                      placeholder="太郎"
                    />
                  </div>
                  <div>
                    <FormLabel
                      id="gendar-label"
                      sx={{
                        mt: 1,
                        ml: 1,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '35%',
                        textAlign: 'left',
                        display: 'inline-block',
                      }}
                    >
                      ご就職先企業名
                    </FormLabel>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      value={formState.values.company}
                      onChange={(event) => {
                        handleChange(event, 'company', event.target.value);
                      }}
                      sx={{ mt: 0, ml: 1, mr: 0, mb: 2, width: '55%' }}
                      placeholder=""
                    />
                  </div>
                  <div>
                    <FormLabel
                      id="gendar-label"
                      sx={{
                        mt: 1,
                        ml: 1,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '35%',
                        textAlign: 'left',
                        display: 'inline-block',
                      }}
                    >
                      支店名
                    </FormLabel>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      value={formState.values.branch}
                      onChange={(event) => {
                        handleChange(event, 'branch', event.target.value);
                      }}
                      sx={{ mt: 0, ml: 1, mr: 0, mb: 2, width: '55%' }}
                      placeholder=""
                    />
                  </div>
                  <div>
                    <FormLabel
                      id="gendar-label"
                      sx={{
                        mt: 1,
                        ml: 1,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '35%',
                        textAlign: 'left',
                        display: 'inline-block',
                      }}
                    >
                      営業担当者名
                    </FormLabel>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      value={formState.values.manager}
                      onChange={(event) => {
                        handleChange(event, 'manager', event.target.value);
                      }}
                      sx={{ mt: 0, ml: 1, mr: 0, mb: 2, width: '55%' }}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {(activeStep === 7 && type === 'default') ||
          (activeStep === 3 && type === 'outsourcing') ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  paddingBottom: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    border: '1px solid',
                    backgroundColor: '#0f1941',
                  }}
                >
                  <FormLabel
                    id="gendar-label"
                    style={{
                      backgroundColor: '#0f1941',
                      color: '#ffffff',
                      fontWeight: 'bold',
                      fontSize: '1.1rem',
                      padding: '6px 0px',
                    }}
                  >
                    お疲れ様でした！
                  </FormLabel>
                </div>
                <div
                  style={{
                    padding: '2em 1em',
                    fontSize: '0.9rem',
                  }}
                >
                  <span
                    style={{
                      display: type === 'default' ? '' : 'none',
                    }}
                  >
                    スタートアップ研修・雇入れ時教育訓練のご受講ありがとうございました。
                  </span>
                  <span
                    style={{
                      display: type === 'default' ? 'none' : '',
                    }}
                  >
                    雇入れ時教育訓練のご受講ありがとうございました。
                  </span>
                  <br />
                  こちらで研修は修了となります。
                  <br />
                  本研修で学んでいただいたことにご留意頂き、安全にご就業していただくようお願いします。
                  <br />
                  <br />
                  本研修以外にも、何かご不明な点やご不安な点がございましたら、お気軽にLINEよりメッセージをお送りください。
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          <Grid
            item
            xs={7}
            style={{
              textAlign: 'center',
              display: type === 'outsourcing' ? 'none' : '',
            }}
          >
            <AppBar
              position="fixed"
              color="primary"
              sx={{
                top: 'auto',
                bottom: -2,
                backgroundColor: '#1d6ad4',
              }}
            >
              <Grid
                container
                style={{
                  height: 55,
                }}
              >
                {/*{activeStep === 6 ? (*/}
                {/*  <React.Fragment>*/}
                {/*    <Grid*/}
                {/*      item*/}
                {/*      sx={{ width: '100%' }}*/}
                {/*      style={{*/}
                {/*        textAlign: 'center',*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <Button*/}
                {/*        color="inherit"*/}
                {/*        aria-label="next key"*/}
                {/*        sx={{*/}
                {/*          marginTop: 1,*/}
                {/*          width: '100%',*/}
                {/*        }}*/}
                {/*        onClick={submit}*/}
                {/*      >*/}
                {/*        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>*/}
                {/*          {'実施報告をして完了'}*/}
                {/*        </span>*/}
                {/*      </Button>*/}
                {/*    </Grid>*/}
                {/*  </React.Fragment>*/}
                {/*) : (*/}
                <React.Fragment>
                  <Grid
                    item
                    xs={6}
                    style={{
                      backgroundColor: '#d9d9d9',
                      textAlign: 'center',
                      display:
                        activeStep === 0 ||
                        activeStep === 4 ||
                        activeStep === 6 ||
                        activeStep === 7
                          ? 'none'
                          : '',
                    }}
                  >
                    <Button
                      color="inherit"
                      aria-label="back key"
                      sx={{
                        backgroundColor: '#d9d9d9',
                        marginTop: 1,
                        width: '100%',
                        color: '#1d6ad4',
                      }}
                      onClick={handleBack}
                    >
                      <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        前へ
                      </span>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={
                      activeStep === 0 ||
                      activeStep === 4 ||
                      activeStep === 6 ||
                      activeStep === 7
                        ? 12
                        : 6
                    }
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      color="inherit"
                      aria-label="next key"
                      sx={{
                        marginTop: 1,
                        width: '100%',
                      }}
                      onClick={
                        activeStep === 6
                          ? submit
                          : activeStep === 7
                          ? () => {
                              liff.closeWindow();
                            }
                          : handleNext
                      }
                      // disabled={activeStep === 4}
                    >
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          width:
                            activeStep === 0 ||
                            activeStep === 4 ||
                            activeStep === 6 ||
                            activeStep === 7
                              ? '100%'
                              : '50%',
                        }}
                      >
                        {activeStep === 0
                          ? '受講する'
                          : activeStep === 4
                          ? '雇い入れ時安全教育訓練へ進む'
                          : activeStep === 6
                          ? '実施報告をして完了'
                          : activeStep === 7
                          ? '完了'
                          : '次へ'}
                      </span>
                    </Button>
                  </Grid>
                </React.Fragment>
                {/*)}*/}
              </Grid>
              {/*</Toolbar>*/}
            </AppBar>
          </Grid>
          <Grid
            item
            xs={7}
            style={{
              textAlign: 'center',
              display: type === 'outsourcing' ? '' : 'none',
            }}
          >
            <AppBar
              position="fixed"
              color="primary"
              sx={{
                top: 'auto',
                bottom: -2,
                backgroundColor: '#1d6ad4',
              }}
            >
              <Grid
                container
                style={{
                  height: 55,
                }}
              >
                <React.Fragment>
                  <Grid
                    item
                    xs={6}
                    style={{
                      backgroundColor: '#d9d9d9',
                      textAlign: 'center',
                      display:
                        activeStep === 0 || activeStep === 2 || activeStep === 3
                          ? 'none'
                          : '',
                    }}
                  >
                    <Button
                      color="inherit"
                      aria-label="back key"
                      sx={{
                        backgroundColor: '#d9d9d9',
                        marginTop: 1,
                        width: '100%',
                        color: '#1d6ad4',
                      }}
                      onClick={handleBack}
                    >
                      <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        前へ
                      </span>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={
                      activeStep === 0 || activeStep === 2 || activeStep === 3
                        ? 12
                        : 6
                    }
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      color="inherit"
                      aria-label="next key"
                      sx={{
                        marginTop: 1,
                        width: '100%',
                      }}
                      onClick={
                        activeStep === 2
                          ? submit
                          : activeStep === 3
                          ? () => {
                              liff.closeWindow();
                            }
                          : handleNext
                      }
                      // disabled={activeStep === 4}
                    >
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          width:
                            activeStep === 0 ||
                            activeStep === 2 ||
                            activeStep === 3
                              ? '100%'
                              : '50%',
                        }}
                      >
                        {activeStep === 0
                          ? '雇い入れ時安全教育訓練へ進む'
                          : activeStep === 2
                          ? '実施報告をして完了'
                          : activeStep === 3
                          ? '完了'
                          : '次へ'}
                      </span>
                    </Button>
                  </Grid>
                </React.Fragment>
                {/*)}*/}
              </Grid>
              {/*</Toolbar>*/}
            </AppBar>
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'必須項目が未入力'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {errMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </span>
  );
};

export default TrainingForm;
