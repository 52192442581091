import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import BusinessIcon from '@mui/icons-material/Business';
import TrainIcon from '@mui/icons-material/Train';
import YenIcon from '@mui/icons-material/CurrencyYen';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PlaceIcon from '@mui/icons-material/Place';

const AD_EBIS_TRACKING_PARAM =
  'utm_source=line&utm_medium=referral&utm_campaign=spot&utm_content=job_recom&argument=GxW6vfJd&dmai=a61a46f0986b45';

type SpotJobProps = {
  job: any;
};

const SpotJob: React.FC<SpotJobProps> = ({ job }) => {
  const onClickItem = () => {
    window.location.href = `${job.url}?${AD_EBIS_TRACKING_PARAM}`;
  };

  return (
    <ListItem alignItems="flex-start" onClick={onClickItem}>
      <ListItemAvatar>
        <Avatar alt="Job image" src={job.image} />
      </ListItemAvatar>
      <div>
        <ListItemText
          primary={job.title}
          secondary={
            <React.Fragment>
              <BusinessIcon style={{ fontSize: 12 }} />
              {job.industryType}
              <br />
              <TrainIcon style={{ fontSize: 12 }} />
              {job.station}
              <br />
              <PlaceIcon style={{ fontSize: 12 }} />
              {job.locations}
              <br />
              <YenIcon style={{ fontSize: 12 }} />
              {job.salary}
              <br />
              <DateRangeIcon
                style={{
                  fontSize: 12,
                  display: job.searchWorkingDays ? '' : 'none',
                }}
              />
              {job.searchWorkingDays}
            </React.Fragment>
          }
        />
      </div>
    </ListItem>
  );
};

export default SpotJob;
