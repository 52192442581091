import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  isLoaded,
  retrieveProfile,
  selectProfile,
} from '../features/profile/profileSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import liff from '@line/liff';
import AdvancePayEntryForm from '../components/advancePayEntryForm';
import AdvancePayEntryConfirm from '../components/advancePayEntryConfirm';

import {
  isCreated,
  retrieveAdvancePayEntry,
  selectAdvancePayEntry,
  isEdit,
} from '../features/advancePay/advancePayEntrySlice';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function AdvancePayEntry() {
  const dispatch = useAppDispatch();
  const profile: any = useAppSelector(selectProfile);
  const loaded = useAppSelector(isLoaded);
  const created = useAppSelector(isCreated);
  const entry: any = useAppSelector(selectAdvancePayEntry);
  const edit: any = useAppSelector(isEdit);

  let branch = 'default';
  try {
    const search = useLocation().search;
    const query = queryString.parse(search);
    branch =
      String(query['branch']) === 'dr' ||
      String(query['branch']) === 'staffing' ||
      String(query['branch']) === 'inhouse'
        ? String(query['branch'])
        : 'default';
  } catch (e) {
    console.error(e);
  }

  console.log('branch' + branch);

  useEffect(() => {
    document.title = '前給申し込み';

    if (!loaded) {
      dispatch(
        retrieveAdvancePayEntry({
          userId: userId(),
        }),
      );
      dispatch(
        retrieveProfile({
          userId: userId(),
        }),
      );
      //console.log(userId);
    }
  }, [loaded]);

  const [open, setOpen] = React.useState(!userId());
  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  return (
    <span>
      {!loaded ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : entry ? (
        edit ? (
          <div>
            <AdvancePayEntryConfirm
              created={created}
              profile={profile}
              entry={entry}
              branch={branch}
              edit={edit}
            />
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )
      ) : (
        <AdvancePayEntryForm
          profile={profile}
          created={created}
          branch={branch}
          entry={entry}
          edit={edit}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            スマートフォン版LINEでご利用になれます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
