import * as React from 'react';
import { useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import liff from '@line/liff';
import {
  selectContractRenew,
  retrieveContractRenew,
  isLoaded,
} from '../features/contractRenewal/contractRenewalSlice';
import ContractRenewalForm from '../components/contractRenewalForm';

export default function ContractRenewal() {
  const dispatch = useAppDispatch();
  const contractRenew = useAppSelector(selectContractRenew);
  const loaded = useAppSelector(isLoaded);
  const [open, setOpen] = React.useState(!userId());
  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  useEffect(() => {
    document.title = '契約更新確認';

    if (!loaded) {
      dispatch(retrieveContractRenew({ userId: userId() }));
    }
  }, [loaded]);

  return (
    <div>
      {!loaded ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <ContractRenewalForm contractRenewal={contractRenew} />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            スマートフォン版LINEでご利用になれます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
