import * as React from 'react';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import {
  isLoaded,
  retrieveEntry,
  selectEntry,
} from '../features/entry/entrySlice';
import Box from '@mui/material/Box';
import LiquidForm from '../components/LiquidForm';

export default function Liquid() {
  const dispatch = useAppDispatch();
  const entry: any = useAppSelector(selectEntry);
  const loaded = useAppSelector(isLoaded);

  useEffect(() => {
    document.title = '車輛通勤許可申請';

    if (!loaded) {
      dispatch(
        retrieveEntry({
          userId: userId(),
        }),
      );
    }
  }, [loaded]);

  return (
    <span>
      {!loaded ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : entry ? (
        <LiquidForm entry={entry} />
      ) : null}
    </span>
  );
}
