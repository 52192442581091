import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import PlaceIcon from '@mui/icons-material/Place';
import TrainIcon from '@mui/icons-material/Train';
import YenIcon from '@mui/icons-material/CurrencyYen';
import { Chip, Stack } from '@mui/material';

type JobProps = {
  job: any;
};

const Job: React.FC<JobProps> = ({ job }) => {
  const navigate = useNavigate();

  const onClickItem = (j: any) => {
    navigate(`/detail/${j.number}`);
  };

  return (
    <ListItem
      alignItems="flex-start"
      onClick={() => {
        onClickItem(job);
      }}
    >
      <ListItemAvatar>
        <Avatar alt="Job image" src={job.image} />
      </ListItemAvatar>
      <div>
        <ListItemText
          primary={job.title}
          secondary={
            <React.Fragment>
              <BusinessIcon style={{ fontSize: 12 }} />
              {job.industryType}
              <br />
              <PlaceIcon style={{ fontSize: 12 }} />
              {job.locations}
              <br />
              <TrainIcon style={{ fontSize: 12 }} />
              {job.station}
              <br />
              <YenIcon style={{ fontSize: 12 }} />
              {job.salary}
              <br />
            </React.Fragment>
          }
        />
        <Stack
          direction="row"
          spacing={1}
          style={{
            padding: '.2em 0',
          }}
        >
          {job.conditions.map((condition: string, index: number) => {
            return index < 3 ? <Chip key={index} label={condition} /> : null;
          })}
        </Stack>
      </div>
    </ListItem>
  );
};

export default Job;
