import liff from '@line/liff';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import PeriodicSurveyForm from '../components/periodicSurveyForm';
import { userId } from '../utils/liff';

export default function PeriodicSurvey() {
  const [open, setOpen] = React.useState(!userId());
  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  useEffect(() => {
    document.title = 'LINE定期アンケート';
  }, []);

  return (
    <div>
      <PeriodicSurveyForm />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            スマートフォン版LINEでご利用になれます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
