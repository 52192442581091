import liff from '@line/liff';
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  ContactObject_PeriodicSurvey,
  answerPeriodicSurvey,
  selectContractRenewStatus,
} from '../features/periodicSurvey/periodicSurveySlice';
import { userId } from '../utils/liff';

type PeriodicSurveyFormProps = {
  //
};

interface PeriodicSurveyFormState extends ContactObject_PeriodicSurvey {
  /** 就業開始入力日（LINE）のラジオボタン選択値 */
  line_input_start_date_select?: string;
}

type QuestionSectionParam = {
  no: number;
  title: string;
  hint?: string;
  field: keyof PeriodicSurveyFormState;
  type: 'radio' | 'checkbox';
  options: {
    value: string;
    label?: string;
    type?: 'select' | 'date';
    field?: keyof PeriodicSurveyFormState;
  }[];
  onChange?: (value: string | number | null) => void;
};

const PeriodicSurveyForm: React.FC<PeriodicSurveyFormProps> = () => {
  const [formState, setFormState] = useState<PeriodicSurveyFormState>({});
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const periodicSurveyStatus = useAppSelector(selectContractRenewStatus);
  const dispatch = useAppDispatch();

  const handleChange = (values: PeriodicSurveyFormState) => {
    if (values.line_want_status === '') {
      // すべての回答をリセット
      setFormState(() => ({
        line_want_status: values.line_want_status,
      }));
      setCanSend(true);
    } else {
      setFormState((formState) => {
        const state = { ...formState, ...values };
        const send =
          state.line_want_status &&
          (state.line_input_start_date === '決まっていない' ||
            state.line_input_start_date_select) &&
          state.line_employment_type &&
          state.line_preferred_job?.length &&
          state.line_preferred_perm;
        setCanSend(!!send);
        return state;
      });
    }
  };

  const handleSubmit = (values: PeriodicSurveyFormState) => {
    // ２重送信防止
    if (!['idle', 'failed'].includes(periodicSurveyStatus)) {
      return;
    }
    setIsConfirmOpen(false);
    // 回答送信
    dispatch(
      answerPeriodicSurvey({
        userId: userId(),
        values,
      }),
    );
  };

  useEffect(() => {
    if (periodicSurveyStatus === 'finished') {
      const text = ['【お仕事探しご状況アンケート：回答完了】'];
      text.push(
        `現在の求職状況：${
          formState.line_want_status === 'WANT'
            ? '仕事を探している'
            : '仕事を探していない'
        }`,
      );
      if (formState.line_want_status === 'WANT') {
        text.push(
          `ご希望の就業開始時期：${
            formState.line_input_start_date
              ? `${moment(formState.line_input_start_date).format(
                  'YYYY年M月D日',
                )}`
              : '決まっていない'
          }`,
        );
        text.push(`ご希望の働き方：${formState.line_employment_type}`);
        text.push(
          `ご希望の職種：${
            formState.line_preferred_job?.length
              ? Array.from(formState.line_preferred_job).join('、')
              : '未選択'
          }`,
        );
        text.push(`正社員・契約社員の求人：${formState.line_preferred_perm}`);
      }
      liff.sendMessages([{ type: 'text', text: text.join(`\n`) }]);
    }
  }, [periodicSurveyStatus]);

  /**
   * 質問
   */
  const QuestionSection: React.VFC<QuestionSectionParam> = (param) => {
    const optionsElement = () => {
      const [openDatePicker, setOpenDatePicker] = useState(false);
      // ラジオボタン
      switch (param.type) {
        case 'radio':
          return (
            <RadioGroup
              aria-labelledby={param.field}
              name={param.field}
              value={formState[param.field]}
              onChange={(event) => {
                const option = param.options.find(
                  (opt) => opt.value === event.target.value,
                );
                // 日付のライオボタンを選択したらカレンダーを開く
                if (option?.type === 'date') {
                  setOpenDatePicker(true);
                  return;
                }
                const values = { [param.field]: event.target.value };
                handleChange(values);
                if (param.onChange) {
                  param.onChange(event.target.value);
                }
              }}
            >
              {param.options.map((option, index) => {
                return (
                  <FormControlLabel
                    key={`${param.field}_option_${index}`}
                    value={option.value}
                    control={<Radio sx={{ paddingY: '4px' }} />}
                    disabled={periodicSurveyStatus !== 'idle'}
                    label={
                      option.type === 'date' ? (
                        <MobileDatePicker
                          inputFormat="yyyy/M/d"
                          toolbarFormat="yyyy/M/d"
                          value={formState.line_input_start_date || null}
                          minDate={new Date()}
                          open={openDatePicker}
                          onOpen={() => setOpenDatePicker(true)}
                          onClose={() => setOpenDatePicker(false)}
                          onChange={(date) => {
                            if (option.field) {
                              const value = date
                                ? date.setUTCHours(0, 0, 0, 0)
                                : null;
                              const values: any = {
                                [option.field]: value,
                              };
                              if (value) {
                                values[param.field] = option.value;
                              }
                              handleChange(values);
                              if (param.onChange) {
                                param.onChange(value);
                              }
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              placeholder="yyyy/m/d"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onClick={() => setOpenDatePicker(true)}
                              {...params}
                            />
                          )}
                        />
                      ) : (
                        <span style={{ fontSize: '0.9rem' }}>
                          {option.label || option.value}
                        </span>
                      )
                    }
                  />
                );
              })}
            </RadioGroup>
          );
        // チェックボックス
        case 'checkbox': {
          const selectedValues = (formState[param.field] as string[]) || [];
          return (
            <FormGroup>
              {param.options.map((option, index) => (
                <FormControlLabel
                  key={`${param.field}_option_${index}`}
                  value={option.value}
                  control={
                    <Checkbox
                      sx={{ paddingY: '4px' }}
                      value={option.value}
                      checked={selectedValues.includes(option.value)}
                      disabled={periodicSurveyStatus !== 'idle'}
                      onChange={(event) => {
                        let values = selectedValues.slice();
                        const { value, checked } = event.target;
                        if (checked) {
                          values.push(value);
                        } else {
                          values = values.filter((v) => v !== value);
                        }
                        handleChange({ [param.field]: values });
                      }}
                    />
                  }
                  label={
                    <span style={{ fontSize: '0.9rem' }}>
                      {option.label || option.value}
                    </span>
                  }
                />
              ))}
            </FormGroup>
          );
        }
      }
    };

    return (
      <>
        <FormControl key={`question_${param.no}-${param.field}`}>
          <Stack my={2}>
            <Box>
              <FormLabel
                id={param.field}
                style={{ color: '#0f1941', fontSize: '0.9rem' }}
              >
                <Typography component="span">Q{param.no}.</Typography>
                <Typography component="span">{param.title}</Typography>
              </FormLabel>
            </Box>
            {param.hint && (
              <Typography component="span" ml={3} fontSize={14}>
                {param.hint}
              </Typography>
            )}
          </Stack>
          {optionsElement()}
        </FormControl>
      </>
    );
  };

  /**
   * 閉じるボタン
   */
  const CloseButton: React.VFC = () => {
    const scrollRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
      scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
      <Box ref={scrollRef}>
        <Button
          color="inherit"
          aria-label="next key"
          sx={{
            marginTop: 1,
            width: '100%',
          }}
          onClick={() => {
            liff.closeWindow();
          }}
        >
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>閉じる</span>
        </Button>
      </Box>
    );
  };

  return (
    <Box pb={8}>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#0f1941',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            お仕事探しに関するアンケート
          </Typography>
        </Toolbar>
      </AppBar>
      {
        <Stack m={2} padding={2} spacing={4}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src="/randstad-logo.png" alt="logo" width="200px" />
          </div>
          <Stack spacing={4}>
            <Box fontSize={'0.9rem'}>
              こんにちは！
              <br />
              お仕事探しのご状況確認アンケートです。
              <br />
              ※全1～5問：所要時間30秒～2分
            </Box>
          </Stack>

          <Stack spacing={2}>
            <QuestionSection
              no={1}
              title="現在の求職状況を教えてください"
              field="line_want_status"
              type={'radio'}
              options={[
                { label: '仕事を探している', value: 'WANT' },
                { label: '仕事を探していない', value: '' },
              ]}
            ></QuestionSection>
            {formState.line_want_status === 'WANT' && (
              <>
                <QuestionSection
                  no={2}
                  title="ご希望の就業開始時期を教えてください"
                  field="line_input_start_date_select"
                  type={'radio'}
                  options={[
                    {
                      type: 'date',
                      value: '決まっている',
                      field: 'line_input_start_date',
                    },
                    { value: '決まっていない' },
                  ]}
                ></QuestionSection>
                <QuestionSection
                  no={3}
                  title="ご希望の働き方を教えてください"
                  field="line_employment_type"
                  type={'radio'}
                  options={[
                    { value: 'フルタイムの長期派遣' },
                    { value: '時短や扶養内の長期派遣' },
                    { value: '短期派遣' },
                    { value: '派遣は希望しない' },
                  ]}
                ></QuestionSection>
                <QuestionSection
                  no={4}
                  title="ご希望の職種を教えてください"
                  hint="(複数選択可能)"
                  field="line_preferred_job"
                  type={'checkbox'}
                  options={[
                    { value: '事務全般' },
                    { value: 'コールセンター・営業・接客販売' },
                    { value: '工場・製造系' },
                    { value: '倉庫・物流・軽作業' },
                    { value: 'エンジニア（IT・技術）' },
                    { value: 'ドライバー・配送・送迎' },
                  ]}
                ></QuestionSection>
                <QuestionSection
                  no={5}
                  title="正社員・契約社員の求人に興味がありますか？"
                  field="line_preferred_perm"
                  type={'radio'}
                  options={[{ value: '興味がある' }, { value: '興味がない' }]}
                ></QuestionSection>
              </>
            )}

            {periodicSurveyStatus === 'finished' && (
              <Stack pt={4} spacing={4}>
                <Stack spacing={2} fontSize={'14px'}>
                  <Typography fontWeight={'bold'}>
                    アンケートへのご協力ありがとうございました！
                  </Typography>
                  <Stack spacing={4}></Stack>
                  {formState.line_want_status !== 'WANT' ? (
                    <p>
                      アンケートは以上です。
                      <br />
                      お仕事探しでお困りの事があれば、いつでもご連絡ください。
                    </p>
                  ) : (
                    <>
                      <p>
                        アンケートは以上です。
                        <br />
                        ご条件に合うお仕事がございましたらご連絡させていただきます！
                      </p>
                      <p>
                        また、ランスタッド公式LINEではお仕事探しに便利なお仕事レコメンド機能がございます！
                      </p>
                      <p>
                        トーク画面にあるメニューにある「求人リストのプロフィール条件設定」から条件を選択していただくと、条件に合ったお仕事をお届けできます。
                        <br />
                        是非ご活用ください！
                      </p>
                    </>
                  )}
                </Stack>
                <CloseButton />
              </Stack>
            )}

            {periodicSurveyStatus === 'failed' && (
              <Stack py={8} spacing={4}>
                <div>
                  エラーのため回答に失敗しました。
                  <br />
                </div>
                <CloseButton />
              </Stack>
            )}
          </Stack>

          <AppBar
            position="fixed"
            color="primary"
            sx={{
              top: 'auto',
              bottom: 0,
              backgroundColor:
                periodicSurveyStatus !== 'idle' || !canSend
                  ? '#d9d9d9'
                  : '#1d6ad4',
              display: periodicSurveyStatus === 'finished' ? 'none' : 'block',
            }}
          >
            <Toolbar>
              <Button
                color="inherit"
                aria-label="open drawer"
                sx={{ width: '100%' }}
                disabled={periodicSurveyStatus !== 'idle' || !canSend}
                onClick={() => setIsConfirmOpen(true)}
              >
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {periodicSurveyStatus !== 'submitting' ? (
                    'アンケートを送付する'
                  ) : (
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                      <CircularProgress size={16} />
                      <span>送信しています...</span>
                    </Stack>
                  )}
                </span>
              </Button>
            </Toolbar>
          </AppBar>
        </Stack>
      }

      <Dialog
        open={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            アンケートを送付しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmOpen(false)} autoFocus>
            いいえ
          </Button>
          <Button onClick={() => handleSubmit(formState)}>はい</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PeriodicSurveyForm;
